
//var Promise = require('bluebird').config({ longStackTraces: false, warnings: false }); // Promise polyfill for IE11 (https://github.com/aurelia/skeleton-navigation/issues/282)


import 'bootstrap';
import  {AnimatorVelocity}  from 'aurelia-animator-velocity';
import { LogManager, PLATFORM } from 'aurelia-framework';
import 'aurelia-polyfills';
import 'aurelia-authentication';
import 'aurelia-validation';
import 'aurelia-api';
import settings from 'global.config.js';
import config from 'auth.config.js';

//import * as Bluebird from 'bluebird';
//Bluebird.config({ warnings: false });


export async function configure(aurelia) {
	//LogManager.setLevel(settings.logLevel);
	aurelia.use
		.standardConfiguration()
		 .developmentLogging()
		 
		 
		.plugin(PLATFORM.moduleName('aurelia-animator-velocity'), (instance) => {
			instance.options.duration = 200;
			instance.options.easing = 'linear';
			instance.enterAnimation = { properties: 'fadeIn', options: { easing: 'easeIn', duration: 100 } };
			instance.leaveAnimation = { properties: 'fadeOut', options: { easing: 'easeIn', duration: 100 } };
		})
		.plugin(PLATFORM.moduleName('aurelia-smooth-scroll/smooth-scroll'))
		.plugin(PLATFORM.moduleName('aurelia-dialog'))
		.plugin(PLATFORM.moduleName('aurelia-api'), authconfig => {
    // Register an authentication hosts
		    authconfig.registerEndpoint('auth');
		  })
		.plugin(PLATFORM.moduleName('aurelia-authentication'), (baseConfig) => {
			baseConfig.configure(config);
		})
		.plugin(PLATFORM.moduleName('aurelia-validation'))
		.feature(PLATFORM.moduleName('utils/converters/index'))	// All ValueConverters are registered here
		.feature(PLATFORM.moduleName('utils/renderers/index'));
	
	
	aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('with'),document.body));
	
}
