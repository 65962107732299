import settings from 'global.config.js';

export class BackgroundValueConverter {
	toView(value, fallback) {
		if (value) {
			return (value.startsWith('http') ? `background-image: url('${value}')` : `background-image: url('${settings.baseUrl}${value}')`) + '; background-position : center;';
		}

		if (typeof fallback === 'object') {
			value = fallback.cover;
			return value.startsWith('http') ? `background-image: url('${value}')` : `background-image: url('${settings.baseUrl}${value}')`;
		} else if (fallback) {
			return `background-image: url('${fallback}')`;
		}

		return '';
	}
}
