/* eslint-disable */
/* Objects of this type should be used to send search requests to the Backend via the SearchServices */
export class Query {

	constructor() {
		this.filters = new Array();
		this.sources = new Array();
		this.facets =  new Array();
		this.continuation=false;
	}

	
	addFacet(facet){
		this.facets.push(facet);
	}
	// add given source and return this
	addSource( source) {
		this.sources.push( source )
		return this
	}

	addSources( ...sources) {
		this.sources.push( ...sources )
		return this
	}
	
	anyShareWithUser( userId ) {
		let clause = [];
		clause.push( { fieldId: 'administrative.access.OWN', value:userId, exact:true } );
		clause.push( { fieldId: 'administrative.access.WRITE', value:userId, exact:true } );
		clause.push( { fieldId: 'administrative.access.READ', value:userId, exact:true } );
		
		this.filters.push( clause );
		return this;
	}

	
	anyRecordShareWithUser( userId ) {
		let clause = [];
		clause.push( { fieldId: 'administrative.collectedBy.OWN', value:userId, exact:true } );
		clause.push( { fieldId: 'administrative.collectedBy.WRITE', value:userId, exact:true } );
		clause.push( { fieldId: 'administrative.collectedBy.READ', value:userId, exact:true } );
		
		this.filters.push( clause );
		return this;
	}
	
	// filter given field with given terms
	// all simpleFilters are anded together
	simpleFieldFilter( field, exact, ...terms ) {
		let clause = [];
		terms.forEach( (term) => {
			let filter = { fieldId: field, value:term, exact:exact };
			clause.push( filter )
		} );
		this.filters.push( clause );
		return this;
	}
	
	removeSimpleFieldFilter(field_id){
		let newfilters =this.filters.filter(function( obj ) {
			  return obj[0].fieldId !== field_id;
			});
		console.log("new filters");
		console.log(newfilters);
		this.filters=[];
		this.filters.push.apply(this.filters,newfilters);
		return this;
	}
	

	// looks only in the title
	titleSearch( title ) {
		this.simpleFieldFilter( 'descriptiveData.label', false, title );
		return this;
	}

	// query only this collection
	inCollection( collectionID ) {
		this.simpleFieldFilter( 'collectedIn', true, collectionId );
		return this;
	}

	// query only stuff this user is read shared
	readableByUser( userId ) {
		this.simpleFieldFilter( 'administrative.access', true, userId );
		return this;
	}

	ownedByUser( userId ) {
		this.simpleFieldFilter( 'administrative.access.OWN', true, userId );
		return this;
	}

	withPageSize( pageSize ) {
		this.pageSize = pageSize
		return this
	}

	// one base offset into result pages
	startAtPage( pageOffset) {
		this.page = pageOffset
		return this
	}

	// some text that we look for in not specified field
	query( freeText ) {
		this.simpleFieldFilter( 'anywhere', false, freeText );
		return this
	}

	// starting from 0 an offset
	// this is an alternative to page, don't specify both
	start( offset ) {
		this.start = offset;
		return this;
	}

	// how many items you want back
	// this is an alternative to pageSize, don't specify both
	limit( count ) {
		this.count = count;
		return this;
	}

	/*
	 * Returns a response as soon as one backend source has responded
	 * remaining backend responses are queried with the continuationId sent in the response
	 */
	fastResponse() {
		this.continuation = true;
		return this;
	}

	continueQuery( continuationId) {
		this.continuation=false;
		this.continuationId = continuationId;
		return this;
	}
}
