import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SpaceServices } from 'SpaceServices.js';
import { Space } from 'Space.js';
import settings from 'global.config.js';

@inject(SpaceServices, Router)
export class SpacesStep {

  constructor(spaceServices, router) {
    this.spaceServices = spaceServices;
		this.router = router;
  }

  run(routingContext, next) {
    const routeName = routingContext.config.name;
    if (routeName === 'custom' || settings.redirectToSpace) {
      if (settings.redirectToSpace) {
				routingContext.params.name = settings.spaceName;
				if (routeName === '') {
					this.router.navigate(`home${settings.spacePageName}`);
				}
      }

      return this.spaceServices.getSpace(routingContext.params.name).then((result) => {
        // Load the new space
        if (!(result instanceof Space)) {
          this.spaceServices.active = new Space(result);
          $('head').append(`<style type="text/css" id="spaceStyle">${this.spaceServices.active.css}</style>`);
          $('body').removeClass('with').addClass(this.spaceServices.active.theme).removeClass('pg-loading');

        }
        // Load Stylesheet
        return next();
      })
			.catch((error) => {
        toastr.error('Space does not exist or is corrupted!');
      });
    }

    // Remove space information and reset the theme to WITH
    this.spaceServices.active = null;
    $('#spaceStyle').remove();
    $('body').removeClass().addClass('with');
    return next();
  }
}
