/* eslint-disable */
import { inject } from 'aurelia-framework';
import { AnnotationServices } from 'AnnotationServices.js';

@inject(AnnotationServices)
export class SelectedVocabulary {

  constructor(annotationServices) {
    this.vocabularies = [];

    for (let i = 0 ; i < annotationServices.vocabularies.length; i++) {
      this.vocabularies.push(annotationServices.vocabularies[i].name);
    }
  }

}
