import { noView } from 'aurelia-framework';

@noView
export class NotificationMessageValueConverter {
	toView(value) {
		console.info(value);
		switch (value.activity) {
		case 'GROUP_INVITE':
			return `<strong>${value.senderName}</strong> invites you to join <a href="#/my/group/${value.group}">${value.groupName}</a>`;
		case 'GROUP_INVITE_ACCEPT':
			return `<strong>${value.senderName}</strong> joined <a href="#/my/group/${value.group}">${value.groupName}</a>`;
		case 'GROUP_INVITE_DECLINED':
			return `<strong>${value.senderName}</strong> declined your invitation to join <a href="#/my/group/${value.group}">${value.groupName}</a>`;
		case 'GROUP_REQUEST':
			return `<strong>${value.senderName}</strong> wants to join <a href="#/my/group/${value.group}">${value.groupName}</a>`;
		case 'GROUP_REQUEST_ACCEPT':
			return `You joined <a href="#/my/group/${value.group}">${value.groupName}</a>`;
		case 'GROUP_REQUEST_DENIED':
			return `Your request to join <a href="#/my/group/${value.group}">${value.groupName}</a> was declined`;
		case 'GROUP_REMOVAL':
			return `You have removed from group <a href="#/my/group/${value.group}">${value.groupName}</a>`;
		case 'COLLECTION_SHARE':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.senderName}</strong> wants to share collection <a href="#/my/collection/${value.resource}">${value.resourceName}</a> with <strong>${value.shareInfo.userOrGroupName}</strong>`;
			}
			return `<strong>${value.senderName}</strong> wants to share collection <a href="#/my/collection/${value.resource}">${value.resourceName}</a> with you`;
		case 'COLLECTION_SHARED':
			if (value.shareInfo.sharedWithGroup) {
				return `<a href="#/my/collection/${value.resource}">${value.resourceName}</a> is now shared with <strong>${value.shareInfo.userOrGroupName}</strong>`;
			}
			return `<a href="#/my/collection/${value.resource}">${value.resourceName}</a> is now shared with <strong>${value.senderName}</strong>`;
		case 'EXHIBITION_UNSHARED':
		case 'COLLECTION_UNSHARED':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.resourceName}</strong> is no longer shared with <strong>${value.shareInfo}.userOrGroupName</strong>`;
			}
			return `<strong>${value.resourceName}</strong> is no longer shared with you`;
		case 'COLLECTION_REJECTED':
			return `<strong>${value.senderName}</strong> is not interested in collection <strong>${value.resourceName}</strong>`;
		case 'EXHIBITION_SHARE':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.senderName}</strong> wants to share exhibition <a href="#/my/exhibition/${value.resource}">${value.resourceName}</a> with <strong>${value.shareInfo.userOrGroupName}</strong>`;
			}
			return `<strong>${value.senderName}</strong> wants to share exhibition <a href="#/my/exhibition/${value.resource}">${value.resourceName}</a> with you`;
		case 'EXHIBITION_SHARED':
			if (value.shareInfo.sharedWithGroup) {
				return `<a href="#/my/exhibition/${value.resource}">${value.resourceName}</a> is now shared with <strong>${value.shareInfo.userOrGroupName}</strong>`;
			}
			return `<a href="#/my/exhibition/${value.resource}">${value.resourceName}</a> is now shared with <strong>${value.senderName}</strong>`;
		case 'EXHIBITION_REJECTED':
			return `<strong>${value.senderName}</strong> is not interested in exhibition <strong>${value.resourceName}</strong>`;
		case 'COLLECTION_ANNOTATING_COMPLETED':
			return `Automatic annotating of collection <strong>${value.resourceName}</strong> has finished`;
		case 'RECORD_ANNOTATING_COMPLETED':
			return `Automatic annotating of record <strong>${value.resourceName}</strong> has finished`;
		case 'MESSAGE':
			return `${value.message}`;
		default:
			return `<strong>Unknown Notification Type:</strong>  ${value.activity}`;
		}
	}
}
