/* eslint-disable */
'use strict';
import { LogManager } from 'aurelia-framework';
let Isotope = require('isotope-layout');
let imagesLoaded = require('imagesloaded');
let bridget = require('jquery-bridget');
let sticky = require('jquery-sticky');
let slick = require('slick-carousel');
let magnificPopup=require('jquery.magnific-popup');

$.bridget('isotope', Isotope);
imagesLoaded.makeJQueryPlugin($);

let logger = LogManager.getLogger('Plugin.js');

let settings = $.extend({
	// page
	page: 'default',

	// masonry
	mSelector: '.grid',
	mItem: '.item',
	mSizer: '.sizer',

	// mobile
	mobile: false,

	// mobile menu
	mobileTrigger: '.mobile-trigger',
	transDuration : 0
});

// moblie
if ($(window).width() < 767) {
	settings.mobile = true;
}

export function getSettings(){
	return settings;
}

export function isotopeClear(container) {
/*	this is not needed
	let $container = $(container);
	let $elements = $container.isotope('getItemElements');
	$container.isotope('remove', $elements).isotope('layout');
	*/
}

export function isotopeImagesReveal(container, $items) {
	let $container = $(container);
	let iso = $container.data('isotope');

	let itemSelector = settings.mItem;

	// append to container
	$container.append($items);
	// hide by default
	$items.hide();
	$items.imagesLoaded().progress(function(imgLoad, image) {
		let result = image.isLoaded ? 'loaded' : 'broken';
		if(result=='broken'){
			$(image.img).attr('src','/assets/img/ui/ic-noimage.png')	;
		}
		// get item
		let $item = $(image.img).parents(itemSelector);
		// un-hide item
		$item.show();
		if (iso) {
			iso.appended($item);
		} else {
			return false;
			$.error('iso gone');
		}
	});

	return this;
}

export function initAureliaIsotope(container) {
	logger.info('plugins.js / initAureliaIsotope');
	$(container).isotope({
		itemSelector: settings.mItem,
		transitionDuration: settings.transDuration,
		masonry: {
			columnWidth: settings.mSizer,
			percentPosition: true
		}
	});

}

export function isoRelay(){

	if( $( '[data-grid="isotope" ]' ).length > 0 ) {

		$( '[data-grid="isotope" ]' ).isotope({
			itemSelector: settings.mItem,
			transitionDuration: settings.transDuration,
			masonry: {
				columnWidth: settings.mSizer,
				percentPosition: true
			}
		}).imagesLoaded( function() {
		    // trigger again after images have loaded
			$( '[data-grid="isotope" ]' ).isotope('layout');

		  });

}
}

export function homeIsoImagesLoaded(container, $items,parent) {
	   let $container = $(container);

		let iso = $container.data('isotope');
		if(!iso){
			$container=$( '[data-grid="isotope" ]' );
			$container.isotope({
				itemSelector: settings.mItem,
				transitionDuration: settings.transDuration,
				masonry: {
					columnWidth: settings.mSizer,
					percentPosition: true
				}
			});

			iso=$( '[data-grid="isotope" ]' ).data('isotope');

		}

		let itemSelector = settings.mItem;

		$items.hide();
		$items.imagesLoaded().progress((imgLoad, image) => {

		  if(image.img.className.indexOf('isoimage')>-1){
			let result = image.isLoaded ? 'loaded' : 'broken';
			if (result === 'broken') {
				$(image.img).attr('src', '/assets/img/ui/ic-noimage.png');
			}

			let $item = $(image.img).parents(itemSelector);
			if($item.hasClass('isoload')){
			    $item.removeClass('isoload');
				$item.show();
			}
			if (iso) {
				$container.isotope('layout');
			}
			}
		}).always(function(){

			$( '[data-grid="isotope" ]' ).isotope('layout');
		})
	}

export function aureliaIsoImagesLoaded(container, $items,parent) {
   logger.info('plugin.js / aureliaIsoImagesLoaded');
   let $container = $(container);

	let iso = $container.data('isotope');
	if(!iso){
		$container=$( '[data-grid="isotope" ]' );
		$container.isotope({
			itemSelector: settings.mItem,
			transitionDuration: settings.transDuration,
			masonry: {
				columnWidth: settings.mSizer,
				percentPosition: true
			}
		});

		iso=$( '[data-grid="isotope" ]' ).data('isotope');

	}

	let itemSelector = settings.mItem;

	//$items.hide();
	$items.imagesLoaded().progress((imgLoad, image) => {
	parent.loading=true;
	  if(image.img.className.indexOf('isoimage')>-1){
		let result = image.isLoaded ? 'loaded' : 'broken';
		if (result === 'broken') {
			$(image.img).attr('src', '/assets/img/ui/ic-noimage.png');
		}

		let $item = $(image.img).parents(itemSelector);
		if($item.hasClass('isoload')){
		    $item.removeClass('isoload');
			$item.show();
			if (iso) {
				iso.appended($item);
				$container.isotope('layout');
			}
			else{
				parent.loading=false;
				return false;
			}}
		}
	}).always(function(){

	   parent.loading=false;
	})
}


export function aureliaUnslick(container,$items,hideMissing){
	let $container = $(container);
	if($container.hasClass('slick-initialized'))
	 $(container).slick('unslick');
}

export function aureliaSlickLoaded(container, $items,hideMissing) {

	let $container = $(container);

	$items.hide();
	//$(container).hide();

	$(container).not('.slick-initialized').slick({
		slidesToScroll: 2,
		infinite : false,
		variableWidth: true
	});
	$items.imagesLoaded().progress((imgLoad, image) => {
		let result = image.isLoaded ? 'loaded' : 'broken';
		let $item = $(image.img).parents(".item");
		if (result != 'broken') {
			//$(container).show();
			$item.show();
			let im=$item.find("img");
			let elemWidth=im.width();
			if(!elemWidth){elemWidth="200";}
			$item.css( 'width',elemWidth+'px' );
		}else{
		  if(hideMissing)
		   {
			$(container).slick('slickRemove', $item.attr('data-slick-index') - 1);
		   }
		  else{

			$(image.img).attr('src', '/assets/img/ui/ic-noimage.png');
			$item.show();
			$item.css( 'width','400px' );
		  }
		}

	})


}

export function filterIsotope(container, $filter) {
	logger.info(`plugin.js / filterIsotope: ${$filter}`);

	let $container = $(container);
	let iso = $container.data('isotope');
	iso.arrange({
		filter: $filter
	});
}

export function initRows(hideMissing){


		// rows
		if( $( ' .contentitem' ) && $( ' .contentitem' ).length > 0 ) {

			// each
			$( ' .contentitem' ).each( function(){

				// init
				var $container=$(this);

				//$container.hide();
				var $items=$( '.item', $container );
				$container.not('.slick-initialized').slick({
					slidesToScroll:2,
					infinite : false,
					variableWidth: true
				});


				$items.imagesLoaded().progress((imgLoad, image) => {
						let result = image.isLoaded ? 'loaded' : 'broken';
						let $item = $(image.img).parents(".item");
						if (result != 'broken') {

							$item.show();
							let im=$item.find("img");
							let elemWidth=im.width();
							if(!elemWidth){elemWidth="200";}
							$item.css( 'width',elemWidth+'px' );

						}else{
							 $item.hide();
							  if(hideMissing)
							   {
								//$(container).slick('slickRemove', $item.attr('data-slick-index') - 1);
							   }
							  else{

								$(image.img).attr('src', '/assets/img/ui/ic-noimage.png');
								$item.show();
								$item.css( 'width','400px' );
							  }
							}
				})

			});


		}

};

export function initSearchHeader(){


	// check
	if ( $( '.searchnew' ).length !== 0 ){

		// init sticky
		$( '.searchnew a' ).on('click', function( e ){

			// prevent
			e.preventDefault();

			var $parent = $( this ).parent();
			$parent.toggleClass( 'open' );
			setTimeout(function(){
				$parent.find('input').focus();
			}, 300);

		});

		// close
		$( '.searchnew .cross' ).on( 'click', function( e ){

			// prevent
			e.preventDefault();
			var $parent = $( this ).parent();
			$parent.removeClass( 'open' );
		});
	}
};

// Method to toggle search view mode
/*export function initSearchViewToggle() {
	logger.info('plugins.js / initSearchViewToggle');

	if ($('.searchbar .view').length !== 0) {
		$('.searchbar .view a').on('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			$('.searchbar .view li').removeClass('active');
			$(this).parent().addClass('active');

			let data = $(this).attr('data-view');

			$('.searchresults').find('section').removeClass('active');
			$('.searchresults #' + data + 'list').toggleClass('active');

			if (data === 'grid') {
				$(settings.mSelector).isotope();
			}
		});
	}
}*/

// Method to initialize search column width
export function initSearchColumnAdjustment() {
	logger.info('plugins.js / initSearchColumnAdjustment');

	// internal method
	function adjustColumnSize() {
		// reset
		$('#columnlist .row .column').attr('style', '');
		// get the screen width
		let screenWidth = $(window).width();
		let columnCount = $('#columnlist .row .column').length;
		let columnWidth = $('#columnlist .row .column').first().width();

		// check
		if ((screenWidth > (columnCount * columnWidth)) && (screenWidth > 768)) {
			let newWidth = 100 / columnCount;
			$('#columnlist .row .column').css({
				'width': newWidth + '%'
			});
		}
	}

	// initial adjustment
	adjustColumnSize();

	// window resize
	$(window).resize(() => {
		// initial adjustment
		adjustColumnSize();
	});
}

export function initSearchSticky(){

	// log
	//logger( 'info','plugins.js / initSearchSticky' );

	// check
	if( !settings.mobile) {
		if ( $( '.searchbar' ).length !== 0 ){

			// init sticky
			$( '.searchbar' ).sticky({
				topSpacing: 0
			});
		}
	}
};



// Method to initialize search setting
export function initSearchSetting() {
	logger.info('plugins.js / initSearchSetting');

	// handled by component
	// if ($('.searchbar .settings').length > 0) {
	// 	// attach
	// 	$('.searchbar .settings > a').on('click', function(e) {
	// 		// e
	// 		e.preventDefault();
	// 		// on
	// 		$(this).parent().toggleClass('active');
	// 	});
	// }
}

// Method to initialize nicescroll plugin
// Dependency: nicescroll.js
export function initNicescroll() {
	logger.info('plugin.js / initNicescroll');

	$('body').niceScroll({
		autohidemode: false
	});

	// check action
	if ($('section.action').length !== 0) {
		$('section.action').niceScroll();
	}
}

// Method to init all the action in the collection page
export function tabAction() {
	logger.info('plugin.js / Collection Action');

	// close
	$('.cancel').on('click', function(e) {
		e.preventDefault();
		$('.action').removeClass('active');
		$('.searchresults').removeClass('openfilter');
		// check
		/*if( $( '.searchresults' ).length > 0 ) {
			if( $( '.searchresults' ).hasClass( 'openfilter' ) ) {
				$( '.searchresults' ).removeClass( 'openfilter' );
			}
		}*/
	});

	// open edit & access
	if ($('.action-group').length !== 0) {
		// edit
		$('.editaction').on('click', function(e) {
			e.preventDefault();

			$('.action').removeClass('active');
			$('.action.edit').addClass('active');
		});

		// access
		$('.fa-paper-plane-o').on('click', function(e) {
			e.preventDefault();
			$('.action').removeClass('active');
			$('.action.access').addClass('active');
		});

		// access
		$('.members').on('click', function(e) {
			e.preventDefault();
			$('.action').removeClass('active');
			$('.action.access').addClass('active');
		});

		// $('.collectbutton').on('click', function(e) {
		// 	// e
		// 	e.preventDefault();
		//
		// 	$('.action').removeClass('active');
		// 	$('.action.collect').addClass('active');
		// });

		// $('.fa-download').on('click', function(e) {
		// 	$('.action').removeClass('active');
		// 	$('.action.collect').addClass('active');
		// });
	}

	// open upload
	if ($('a.upload').length !== 0) {
		$('a.upload').on('click', function(e) {
			e.preventDefault();
			$('.action').removeClass('active');
			$('.action.upload').addClass('active');
		});
	}

	// open uploadimage
	if ($('a.uploadimage').length !== 0) {
		$('a.uploadimage').click(function(e) {
			e.preventDefault();
			$('.action').removeClass('active');
			$('.action.upload').addClass('active');
		});
	}

	// open new
	if ($('a.new').length !== 0) {
		$('a.new').on('click', function(e) {
			e.preventDefault();
			$('.action').removeClass('active');
			$('.action.new').addClass('active');
		});
	}

	// open detail area
	if ($('a.detail-control').length !== 0) {
		$('a.detail-control').on('click', function(e) {
			e.preventDefault();
			$('.action').removeClass('active');
			$('.action.api').addClass('active');
		});
	}

	// open members acces
	if ($('.members-access').length !== 0) {
		$('.members-access').on('click', function(e) {
			e.preventDefault();
			$('.action').removeClass('active');
			$('.action.members').addClass('active');
		});
	}

	// add textarea
	if ($('.action').length !== 0) {
		let textarea = '<textarea rows="10" placeholder="Describe your collection"></textarea>';
		$('.action .add').on('click', function(e) {
			e.preventDefault();
			$(this).hide();
			$(this).first().after(textarea);
		});
	}

	$( '.searchbar .settings > a' ).click( function( e ) {

		// prevent
		e.preventDefault();

		$( '.action' ).removeClass( 'active' );
		$( '.action.searchfilter' ).addClass( 'active' );
		$( '.searchresults' ).addClass( 'openfilter');
	});
	// Search settings (this might need to be removed)
	// $('.searchbar .settings > a').click(function(e) {
	// 	// prevent
	// 	e.preventDefault();
	//
	// 	$('.action').removeClass('active');
	// 	$('.action.searchfilter').addClass('active');
	// 	$('.searchresults').addClass('openfilter');
	// });

	$( '.searchbar .settings > a' ).click( function( e ) {

		// prevent
		e.preventDefault();

		$( '.action' ).removeClass( 'active' );
		$( '.action.searchfilter' ).addClass( 'active' );
		$( '.searchresults' ).addClass( 'openfilter');
	});


	// profile
	$('.showprofile').on('click', function(e) {
		// prevent
		e.preventDefault();

		$('.action').removeClass('active');
		$('.action.profile').addClass('active');
	});

	// profile
	$('.editbox').on('click', function(e) {
		// prevent
		e.preventDefault();
		$('.action').removeClass('active');
		$('.action.editsection').addClass('active');
	});

	$('.videobox').on('click', function(e) {
		e.preventDefault();
		$('.action').removeClass('active');
		$('.action.editvideo').addClass('active');
	});

	// multiple select
	$('#multiplecollect').on('click', function(e) {
		// prevent
		e.preventDefault();

		$('.action').removeClass('active');
		$('.action.collect').addClass('active');
	});

	initTooltip();
}


export function initFilterEvent(){

	// log
	//logger( 'info','plugins.js / initFilterEvent' );

	// on filters click
	if( $( '.searchfilter' ).length > 0 ) {

		// each filter
		$( '.filterlist li a' ).each( function(){

			// vars
			var filterid = '',
				$self = $( this );

			// click
			$( this ).on( 'click', function( e ){

				// prevent
				e.preventDefault();

				// get filter id
				filterid = $( this ).attr( 'data-filter' );

				// toggle
				$( '#'+filterid ).toggleClass( 'active' );
				$( this ).parent().toggleClass( 'active' );
			});


		});

		// close event
		$( document ).mouseup( function ( e )
		{
		    /*kalo ada yg ngebuka
		    $( '.searchbox-content ul' ).each( function(){
		    	if( $(this).hasClass( 'active') && $( this ).has( e.target ).length === 0 &&  !$( this ).is( e.target )) {
		    		$( this ).removeClass( 'active');
		    	}
		    });

		    // kalo ada yg ngebuka
		    $( '.filterlist li' ).each( function(){
		    	if( $(this).hasClass( 'active')) {
		    		$( this ).removeClass( 'active');
		    	}
		    });*/

		    /*
		    var container = $( '#'+filterid ),
		    	toggleNav = $self;

		    // if the target of the click isn't the container, nor a descendant of the container
		    if  ( !container.is( e.target ) && container.has( e.target ).length === 0 && !toggleNav.is( e.target ))
		    {
		        container.removeClass( 'active' );
		        $self.parent().removeClass( 'active' );
		    }*/
		});
	}
};

// Method to initialize isotope
// Dependency: js/vendor/isotope/
export function initIsotope(container) {
	logger.info('plugins.js / initIsotope');
	$(container).isotope({
		itemSelector: settings.mItem,
		transitionDuration: settings.transDuration,
		masonry: {
			columnWidth: settings.mSizer,
			percentPosition: true
		}
	});

	// init filter
	if ($('.filter').length > 0) {
		// get list
		$('.filter .nav li').each(function() {
			// list
			let $list = $(this);
			let data = $list.attr('data-filter');

			// on click
			$('a', this).on('click', function(e) {
				// prevent
				e.preventDefault();

				// filter
				$(settings.mSelector).isotope({
					filter: data
				});

				// reset
				$('.filter .nav li').removeClass('active');
				$list.addClass('active');
			});
		});
	}
	}




// Method to limit character description
export function initCharacterLimiter() {
	logger.info('plugins.js / initCharacterLimiter');

	// check
	if ($('.featured .exhibition .description').length > 0) {
		// elem
		let $texts = $('.featured .exhibition .description .text');
		if ($texts.text().length > 240) {
			let tmp = $texts.text().substr(0, 240) + '...';
			$texts.text(tmp);
		}
	}
}


export function showMoreLess(container, showChar){
	let ellipsestext = "...";
    let moretext = "Show more";
    let lesstext = "Show less";
    let $container = $(container);


    $container.each(function() {
        var content = $(this).html();

        if(content.length > showChar) {

            var c = content.substr(0, showChar);
            var h = content.substr(showChar, content.length - showChar);

            var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';

            $(this).html(html);
        }

    });

    $(".morelink").on('click',function(){
        if($(this).hasClass("less")) {
            $(this).removeClass("less");
            $(this).html(moretext);
        } else {
            $(this).addClass("less");
            $(this).html(lesstext);
        }
        $(this).parent().prev().toggle();
        $(this).prev().toggle();
        return false;
    });
}

// Method to initialize filter stick plugin
// Dependency: sticker
export function initFilterStick() {
	logger.info('plugins.js / initSticky');

		if ($('.filter').length !== 0) {
			// init sticky
			$('.filter').sticky({
				topSpacing: 74
			});

			$('.filter').css('z-index',9);
			// on scroll
			$(window).on('scroll touchmove', function() {
					let offset = $('.partners').offset();
					let topPos = offset.top - 74;

					// set class
					if ($(document).scrollTop() >= topPos) {
						$('.filter').addClass('unstick');
						$('.filter').css('z-index',9);

					} else {
						$('.filter').removeClass('unstick');



					}
					$('.filter').css('z-index',9);
			});
		}

}

// Method to initialize main navigation in mobile view
export function initMobileMenu() {

	// check
	$( settings.mobileTrigger ).click( function( e ){

		// preventr
		e.preventDefault();

		// set
		$( this ).toggleClass( 'active' );
		$( 'nav' ).toggleClass( 'active' );
		$( '.searchnew' ).toggleClass( 'active' );
	});
}

// Method to wrap filter with dropdown menu in mobile view
export function initMobileFilter() {
	// check
	if (settings.mobile && $('.filter .nav').length !== 0) {
		// var
		let dropdownHTML = '<a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Filter <span></span></a>';

		$('.filter .nav').addClass('dropdown-menu').wrap('<div class="select-filter dropdown"></div>');
		$('.select-filter').prepend(dropdownHTML);
	}
}


export function changeTooltipTitle($elem,newtitle){
    $elem.tooltip('hide');
    $elem.attr('data-original-title', newtitle);
    $elem.tooltip('fixTitle');

}
// Method to initialize tooltip funciton
// Dependency: bootstrap.js
export function initTooltip() {
	// init
	$('[data-toggle="tooltip"]').tooltip({
	    trigger : 'hover'
	});
}

// Method to initialize hide message function
export function hideMessage() {
	logger.info('plugin.js / hideMessage');

	// check
	if ($('section.message').length !== 0) {
		$('.message-body a').on('click', function(e) {
			e.preventDefault();
			$(this).closest('.message').addClass('close');
		});
	}
}

// Method to toggle collection listing from grid to list
export function changeList() {
	logger.info('plugin.js / changeList');

	// check
	if ($('.list-control').length !== 0) {
		$('.list-control .fa-th').on('click', function(e) {
			e.preventDefault();

			$(this).toggleClass('active');
			$(this).parent().find('.fa-bars').toggleClass('active');
			$('.list-body').toggleClass('grids');
			$('.list-body').toggleClass('list');
		});

		$('.list-control .fa-bars').on('click', function(e) {
			e.preventDefault();

			$(this).toggleClass('active');
			$(this).parent().find('.fa-th').toggleClass('active');
			$('.list-body').toggleClass('grids');
			$('.list-body').toggleClass('list');
		});
	}
}

// Method to initialize media viewer
export function initMediaViewer() {
	logger.info('plugin.js / initMediaViewer');

	// check
	if( $( '[data-view="inline" ]' ).length > 0 ) {

		// on click
		$( '[data-view="inline" ]' ).on( 'click', function( e ){


			e.preventDefault();

			// disable scroll on main
			$('body').css('overflow', 'hidden');

			// open
			$('.itemview').fadeIn();
		});

		// on click
		$('.closemedia').on('click', function(e) {
			e.preventDefault();

			// open
			$('.itemview').fadeOut();

			// disable scroll on main
			$('body').css('overflow', 'visible');
		});
	}
}




export function initCarousel() {
	logger.info('plugins.js / initCarousel');
	// check
	if ($('.carouselexhibition').length > 0) {
		// init
		$('.carouselexhibition').slick({
			'accessibility': true,
			'infinite': false,
			'dots': true,
			'arrows': true,
			'prevArrow': '<a class="slick-prev"><img src="/assets/img/ui/ic-left-arrow.png"></a>',
			'nextArrow': '<a class="slick-next"><img src="/assets/img/ui/ic-right-arrow.png"></a>'
		});

		// on btn click
		$('.carouselexhibition .main .btn').on('click', function(e) {
			// prevent
			e.preventDefault();

			// click
			$('.carouselexhibition').slick('slickNext');
		});
	}
	// check
	if( $( '.herobanner' ).length > 0 ) {


		// init
		$( '.herobanner' ).slick({
			'autoplay': true,
            'accessibility' : true,
			'slide': '.elem',
			'dots'   : true,
			'customPaging' : function(slider, i) {
				if(i==0){
					return '<span class="herobannertext"><strong>Welcome</strong></span>';
				}
				else if(i==1){
					return '<span class="herobannertext"><strong>Featured Collection</strong></span>';
				}
				else if(i==2){
					return '<span class="herobannertext"><strong>Featured Exhibition</strong></span>';
				}
				else if(i==3){
					return '<span class="herobannertext">Featured Space</span>';
				}

		    },
			'dotsClass' : 'container slick-dots',
			'arrows' : false,
			'speed': 2000,
			'autoplaySpeed': 6000
		});

	}
}

// Method to expand text on exhibition
export function initExpandExhibitionText() {
	logger.info('plugins.js / initExpandExhibitionText');

	// check
	if ($('.exhibitionplayer .expand').length > 0) {
		// each
		$('.exhibitionplayer .expand a').on('click', function(e) {
			// prevent
			e.preventDefault();

			// set
			$(this).parent().parent().parent().toggleClass('expanded');
		});
	}
}

// Method to initialize exhibition image zoom
export function initImageZoom() {
	logger.info('plugins.js / initImageZoom');

	// check
	if ($('.imagezoom').length > 0) {
		// init
		$('.imagezoom').magnificPopup({
			type: 'image'
		});
	}

	// check
	if ($('.iframezoom').length > 0) {
		// init
		$('.iframezoom').magnificPopup({
			type: 'iframe'
		});
	}
}

// Method to initialize more info on item page
export function initShowMore() {
	logger.info('plugins.js / initShowMore');

	// check
	if ($('.listmore').length > 0) {
		// each
		$('.listmore').each(function() {
			// get target
			let target = $(this).attr('data-target');

			// click
			$(this).on('click', function(e) {
				// e
				e.preventDefault();

				// check
				if ($('#' + target).hasClass('show')) {
					// remove
					$('#' + target).removeClass('show');
					$(this).html('SHOW MORE INFO');
				} else {
					// add
					$('#' + target).addClass('show');
					$(this).html('SHOW LESS INFO');
				}
			});
		});
	}
}

// Method to initialize multiple select on search
export function initMultipleSelect() {
	logger.info('plugins.js / initShowMore');

	// check
	if ($('.selectitem').length > 0) {
		// on click
		$('.selectitem').each(function() {
			// on click
			$(this).click(function() {
				// save
				let $this = $(this);

				// check
				if ($this.is(':checked')) {
					// set selected
					$this.closest('.item').addClass('selected');

					// show
					showHideCollectButton();
				} else {
					// set selected
					$this.closest('.item').removeClass('selected');

					// show
					showHideCollectButton();
				}
			});
		});
	}

	// function
	function showHideCollectButton() {
		// check
		if ($('.item.selected').length > 0) {
			$('#multiplecollect').addClass('show');
		} else {
			$('#multiplecollect').removeClass('show');
		}
	}
}

// Init smoothscroll
export function initSmoothScroll() {
	logger.info('plugins.js / initSmoothScroll');

	// check
	if ($('.about').length > 0) {
		// init
		$('a[href*="#"]:not([href="#"])').click(function() {
			if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
				let target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {
					$('html, body').animate({
						scrollTop: target.offset().top - 72
					}, 1000);
					return false;
				}
			}
			return true;
		});
	}
}




// method to initialize chart
export function initChart(){

	// log
	//logger( 'info plugins.js / initChart' );

	// check
	if( $( '.chart' ).length > 0 ) {

		// each
		$( '.chart' ).each( function(){

			// pie chart
			$( this ).easyPieChart({
				'scaleColor' : false,
				'size': 160,
				'lineCap' : 'round',
				'lineWidth' : 6,
				'barColor' : '#eb64e0'

			});
		});
	}
};


// method to init space editor paging
export function initSpaceEditor(){

	// log
	//logger( 'info plugins.js / initSpaceEditor' );

	// add event
	// action
	$( '[data-content]').each( function(){

		// add event
		$( this ).on( 'click', function(){

			// get target
			var target = $( this ).attr( 'data-content' );

			// show
			$( '.editor-content-section' ).hide();
			$( target ).show();

			// set anchor
			$( '[data-content]').parent().removeClass( 'active' );
			$( this ).parent().addClass( 'active' );

			// initialize slick in here, otherwise width wont be recognize
			if( target == "#editor-content-pagesettings" ) {
				// initialize editor Listing
				//initSpaceEditorList();
			}
		});
	});
};

// method to init slick on space editor
export function initSpaceEditorList(){

	// log
	//logger( 'info plugins.js / initSpaceEditorList' );


	// on window load
	$( window ).load( function(){

		// rows
		if( $( '.editor-main .contentitem' ).length > 0 ) {

			// each
			$( '.editor-main .contentitem' ).each( function(){

				// init
				$( this ).slick({
					slidesToShow: 4,
					'infinite' : false,
					variableWidth: true
				});

				// img
				$( '.item', this ).each( function(){

					// vars
					var elemWidth = 200;

					// check
					if( $( 'figure', this ).hasClass( 'empty' ) ) {

						// get width
						elemWidth = $( this ).find( 'figure').width();
					} else {

						// get width
						elemWidth = $( this ).find( 'figure img ').width();
					}

					// set
					$( this ).css( 'width',elemWidth+'px' );
				});
			});
		}
	});
};

// method to init color picker
export function initColorPicker(){

	// log
	//logger( 'info plugins.js / initColorPicker' );

	// get input color
	// action
	$( 'input.color' ).each( function(){

		// set this
		var self = this,
			currentColor = $( this ).val();

		// init
		$( this ).ColorPicker({
			color: currentColor,
			onShow: function (colpkr) {
				$(colpkr).fadeIn(500);
				return false;
			},
			onHide: function (colpkr) {
				$(colpkr).fadeOut(500);
				return false;
			},
			onChange: function (hsb, hex, rgb) {

				// get
				$( self ).parent().find( '.selected' ).css({
					'background-color' : '#'+hex,
					'border-color' : '#'+hex
				});

				// value
				$( self ).val( '#'+hex );
			}
		});
	});
};

// method to init file upload
export function initFileUpload(){

	// log
	logger( 'info plugins.js / initFileUpload' );

	// search
	$( '[data-upload]').each( function(){

		// click
		$( this ).on( 'click', function(){

			// get link
			var target = $( this ).attr( 'data-upload' );

			// trigger
			$( target ).trigger( 'click' );
		});
	});
};
