import { noView } from 'aurelia-framework';

@noView
export class UserAvatarValueConverter {
	toView(value) {
		if (typeof value.image !== 'undefined') {
			return value.image;
		}
		return (value.category === 'user') ? '/assets/images/user.png' : '/assets/images/groups.svg';
	}
}
