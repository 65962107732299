/* eslint-disable */

import { Record } from 'Record.js';

export class SearchSource{

     constructor(data){
		this.source = "";
		this.title="";
		this.consoleurl="";
		this.items=[];
		this.total=0;
        this.moreitems=false;
        this.supportedfields=[];
        if(data){
           this.load(data);
           }
        }
		
		
		load(data){
			this.source=data.source;
			if(data.title){
				this.title=data.title;
			}
			if(data.consoleurl)
			this.consoleurl=data.consoleurl;
			if(data.items)
			this.items.push.apply(this.items, data.items);
			if(data.moreitems)
			this.moreitems=data.moreitems;
			if(data.total)
			this.total=data.total;
			if(data.supportedFields){
				this.supportedFields=data.supportedFields;
			}
		}
		  

		getSupportedFields(){
			return this.supportedFields;
		}

		addItem(c) {
	           this.items.push(new Record(c));
	        }

		append(newitems){
			this.items.push.apply(this.items, newitems);
			}
	
}
