import {customAttribute, bindable, inject} from 'aurelia-framework';

@inject(Element)
@customAttribute('popover')
@bindable('title')
@bindable('content')
@bindable('placement')
export class Popover {
	constructor(element) {
		this.element = element;
	}

	attached() {
		$(this.element).popover({ title: this.title, placement: this.placement, content: this.content, trigger: 'hover', html: 'true' });
	}

  // titleChanged(newValue){
  //   $(this.element).data('bs.popover').options.title = newValue;
  // }
  //
  // contentChanged(newValue){
  //   $(this.element).data('bs.popover').options.content = newValue;
  // }
  //
  // placementChanged(newValue){
  //   $(this.element).data('bs.popover').options.placement = newValue;
  // }
}
