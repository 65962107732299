/* eslint-disable */
import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import fetchConfig from 'fetch.config.js';
import { checkStatus } from 'fetch.config.js';

@inject(HttpClient)
export class ThesaurusServices {

  constructor(http) {
    http.configure(fetchConfig);
  	this.http = http;
  }

  // word is the prefix of the label you want to return
  // vocabularies is an array of prefixes from which thesauruses you want to have suggestions
  getSuggestions(word, vocabularies ) {
	let vocabs = vocabularies.join( "," );  
    return this.http.fetch(`/thesaurus/suggestions?word=${word}&namespaces=${vocabs}`, {
      method: 'GET'
    }).then(checkStatus).then((response) => {
      return response.json();
    });
  }

}
