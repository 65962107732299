/* eslint-disable no-return-assign */

// LESS Files
import '../assets/styles/override.less';
import '../assets/styles/bootstrap.less';	// Custom build version of bootstrap, with Krish variables - TODO: Remove useless stuff from it
import '../node_modules/font-awesome/less/font-awesome.less';

import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import AppRouterConfig from 'router.config.js';
import { UserServices } from 'UserServices.js';
import { SpaceServices } from 'SpaceServices.js';

@inject(Router, AppRouterConfig, UserServices, SpaceServices)
export class App {

	constructor(router, appRouterConfig, userServices, spaceServices) {
		this.router = router;
		this.userServices = userServices;
		this.appRouterConfig = appRouterConfig;
		this.spaceServices = spaceServices;

		// Toastr Configuration
		toastr.options.closeButton = true;
	}

	activate() {
		this.appRouterConfig.configure();

		// If there is an authenticated user but no current user is defined, make a call and load him.
		// Returning a promise, makes the activate wait until the loading is finished before moving on.
		if (this.userServices.isAuthenticated() && this.userServices.current === null) {
			return Promise.all([
				this.spaceServices.loadWITH(),
				this.userServices.reloadCurrentUser()
			]);
		}

		return this.spaceServices.loadWITH();
	}
}

// Code to allow adding/removing stylesheets at runtime
$.stylesheets = (function() {
	let stylesheets;
	let add;
	let clear;

	add = function(cssfile) {
		$('head').append('<link href="' + cssfile + '" rel="stylesheet" />');
		return stylesheets;
	};

	clear = function() {
		$('head link[rel=stylesheet]').remove();
		return stylesheets;
	};

	return stylesheets = {
		add: add,
		clear: clear
	};
}());
