import { inject } from 'aurelia-framework';
import { UserServices } from 'UserServices.js';
import { SpaceServices } from 'SpaceServices.js';

@inject(UserServices, SpaceServices)
export class Customfooter {

	constructor(userServices, spaceServices) {
		this.userServices = userServices;
		this.spaceServices = spaceServices;
		this.credits = "WITH has received funding from the European Union's ICT Policy Support Programme as part of the Competitiveness and Innovation Framework Programme, under GA n° 621037";
		this.alignLinksLeft = false;
		this.evinos = false;
		this.antikleia = false;
	}

	get space() { return this.spaceServices.active; }

	get isAuthenticated() { return this.userServices.isAuthenticated(); }

	activate(params) {
		if (this.space && this.space.username == "fifties") {
			this.credits = "Fifties in Europe Kaleidoscope is co-financed by the Connecting Europe Facility Programme of the European Union, under GA n. INEA/CEF/ICT/A2017/1568496";
			//Special requiremet from the project
			this.alignLinksLeft = true;
		}
		if (this.space && this.space.username == "evinos") {
			this.evinos = true;
		}
		if (this.space && this.space.username == "antikleia") {
			this.antikleia = true;
		}
	}

}
