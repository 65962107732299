import moment from 'moment';

export function stringComparator(a, b) {
	if (a === null) { a = ''; }
	if (b === null) { b = ''; }
	a = a.toString().toLowerCase();
	b = b.toString().toLowerCase();

	if (a < b) {
		return -1;
	}

	if (a > b) {
		return 1;
	}

	return 0;
}

export function dateComparator(a, b) {
	if (a === null) {
		a = new Date(1900, 0, 1);
	}

	if (b === null) {
		b = new Date(1900, 0, 1);
	}

	return moment(b).diff(moment(a));
}
