export class Notification {

	constructor(data) {
		this.id = data.dbId;
		this.activity = data.activity;
		this.receiver = data.receiver;
		this.sender = data.sender;
		this.pendingResponse = data.pendingResponse;
		this.openedAt = data.openedAt;
		this.resource = data.resource;
		this.shareInfo = {
			userOrGroup: {
				timestamp: data.timestamp,
				machineIdentifier: data.machineIdentifier,
				processIdentifier: data.processIdentifier,
				counter: data.counter,
				date: data.date,
				time: data.time,
				timeSecond: data.timeSecond
			},
			previousAccess: data.previousAccess,
			newAccess: data.newAccess,
			ownerEffectiveIds: data.ownerEffectiveIds,
			userOrGroupName: data.userOrGroupName
		};
		this.resourceName = data.resourceName;
		this.senderName = data.senderName;
		this.message = data.message;
		this.group = data.group;
		this.groupName = data.groupName;
	}

	get image() {
		// TODO: Change the call to return the square image of the sender, and the placeholder below only if no image was found
		return '/assets/images/user.png';
	}

}
