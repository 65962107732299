
import {default as globalsettings} from 'global.config.js';
import { Group } from 'Group.js';

export class Space extends Group {

	constructor(data) {
		super(data);
		this.settings = data.uiSettings ? data.uiSettings : {};	// Initialize settings if missing
		this.isNew = false;
		// Initialize spaces that have missing modules
		if (typeof this.settings.pages === 'undefined') {
			this.setModule('pages', [
				{ name: 'homepage', title: 'Homepage', type: 'homepage', enabled: true }
			]);

			this.setModule('homepage', {
				title: '',
				description: '',
				modules: []
			});

			this.isNew = true;
		}

		if (typeof this.settings.style === 'undefined') {
			this.setModule('style', {
				colors: {
					brand: '#4f7979',
					header: '#4c4c4c',
					footer: '#4c4c4c',
					exhibition: '#ffb608',
					item: '#ff0000',
					collection: '#83bc3f',
					background: '#ffffff'
				},
				fonts: {
					families: {
						primary: 'Open Sans, Helvetica, Arial, Sans Serif',
						secondary: 'Georgia, Times New Roman, Serif'
					},
					colors: {
						primary: '#333333',
						secondary: '#8f8d8d',
						navbar: '#f2f2f2'
					},
					size: '15px'
				}
			});

			this.isNew = true;
		}
	}

	get theme() { return this.username; }			    	// Get the theme to be used
	get css() { return this.settings.style.css; }			// Get the compiled CSS file for this space
	get logo() {											// Overrides the square logo with the thumbnail
		// if (this.avatar && this.avatar.Thumbnail) {
		// 	return `${settings.baseUrl}${this.avatar.Thumbnail}`;
		// }
		if (this.settings.homepage && this.settings.homepage.logo) {
			return `${globalsettings.baseUrl}${this.settings.homepage.logo}`;
		}
		return null;
	}
	get cover() {	// Overrides group cover
		let cover = this.settings.homepage.background;

		if (cover) {
			return cover;
		}

		return '/assets/img/content/background-space.png';
	}

	get groupCover() {
		if (this.settings.homepage && this.settings.homepage.background) {
			return `${globalsettings.baseUrl}${this.settings.homepage.background}`;
		}

		return '/assets/img/content/background-space.png';
	}

	/**
	 * Checks if a module is defined. To be used for checking whether a module should be used or not.
	 * In the future to be changed and check if the user has paid for the use of a specific module and if
	 * he wants to use it.
	 */
	hasModule(name) { return !!this.settings[name]; }

	/**
	 * Gets the settings for a module. This is just JSON, so anything can be saved/retrieved.
	 */
	getModule(name) { return this.settings[name]; }

	/**
	 * Add/Update the module settings
	 */
	setModule(name, options) {
		this.settings[name] = options;
		// TODO: this.spaceServices.saveSettings();
	}

	/**
	 * Delete the settings for a specific module
	 */
	deleteModule(name) {
		delete this.settings[name];
		// TODO: this.spaceServices.saveSettings();
	}

}
