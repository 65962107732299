/* eslint-disable */
import settings from 'global.config.js';
import { inject, TemplatingEngine, TaskQueue } from 'aurelia-framework';
import { SearchServices } from 'SearchServices.js';
import {Router} from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import {tabAction} from 'utils/Plugin.js';

let instance = null;
let tagsinput = require('bootstrap-tagsinput');

@inject( SearchServices, Router, TemplatingEngine,EventAggregator,TaskQueue)
export class Search {

	constructor(searchServices, router, engine,eventAggregator,taskQueue) {
		if (instance) {
			return instance;
		}
		this.engine = engine;
		this.router = router;
		this.searchServices=searchServices;
		this.facets=[];
	    this.numvalues=[];
	    this.taskQueue = taskQueue;

		this.thisVM=this;
		this.inited=false;
		this.ea = eventAggregator;
		this.ea.subscribe('filters-reset', (response) => {console.log("event caught filter reset");$("ul").not("#filter-range, #filter-sources, #filter-extra").find("input[type='checkbox']").not("#has-image").prop("checked", false);$("#facet_tags").tagsinput('removeAll');this.facets=[];this.initFacets();});
		this.ea.subscribe('filters-set', (response) => {console.log("event caught filter set");});

		if (!instance) {
			instance = this;
		}

	}



	attached(){
		 $('#facet_tags').tagsinput({
		        allowDuplicates: false,
		          itemValue: 'id',  // this will be used to set id of tag
		          itemText: 'label' // this will be used to set text of tag
		      });
		 this.initFacets();
		this.tagRemovalListener();

	}

	initTags(){
		$("#facet_tags").tagsinput('removeAll');
		let self=this;
		let dup_array = jQuery.extend(true, [], this.parent.filterselection);
		dup_array.some(function(item,indx){

			if(item.filterID!="sources"){
			  item.values.some(function(newvalue,index){
				    let newitem=item;
				    newitem.filterID=newitem.filterID.replace(/\./g, '_');

	    			$("#facet_tags").tagsinput('add',{ id: newitem.filterID+'#'+index, label: newvalue });
	    			var exists=false;
	    			for(var i=0;i<self.facets[0].filters.length;i++){
	    				if(self.facets[0].filters[i].filterID==newitem.filterID){
	    					exists=true;
	    					break;
	    				}
	    			}
	    			if(!exists){
	    				self.facets[0].filters.push(newitem);
	    			}


	    			});

		    }
		});

	 }



	activate(params) {
		if(params.parent){
		   this.parent = params.parent;}

	}



	/*build the facet ui*/
	 initFacets(){
	    	var selsources=this.parent.initSources;
	    	var allsources=this.parent.sources;

	    	var obj = {};
	    	this.facets=[];
	    	obj['sources']=allsources;
	    	obj['filters']=[];
	    	this.facets.push(obj);
	    	let self=this;
	    	this.initTags(this.parent.filterselection);
	    	tabAction();


	    }

	 /* gets user selection and triggers a new search */
	 setFilters(){
		   let searchfacets=[];

	    	if(this.facets[0].filters.length==0){
	    		this.parent.filterselection=[];
	    	}
	    	else{
	    		for(var i=0;i<this.facets[0].filters.length; i++){
		    		 var filter=this.facets[0].filters[i];
		    		 filter.filterID=filter.filterID.replace(/\_/g, '.');
			         searchfacets.push(filter);}
			    this.parent.filterselection=[];
			    this.parent.filterselection.push.apply(this.parent.filterselection,searchfacets);

	    	}

	  }






	 /* set the keyword tags used in filtered search*/
	 setTags(id,newvalue,event){
		 id=id.replace(/\./g, '_');
		 if(event.target.checked || event.target.href){
			var exists=false;
	    	var filterfound=false;
	    	filterfound=this.facets[0].filters.some(function(item){
	    		item.filterID=item.filterID.replace(/\./g, '_');
	    		if(item.filterID===id ){
    		    	if($.inArray(newvalue, item.values)>-1){
    		    		//value is already there
    		    		exists=true;
    		    	}
    		    	else{
    		    		$("#facet_tags").tagsinput('add',{ id: id+'#'+item.values.length, label: newvalue });

    		    		item.values.push(newvalue);
    		    	}
    		    	return true;

    		    }

	    	});

	    	if(!filterfound){

	    		$("#facet_tags").tagsinput('add',{ id: id+'#0', label: newvalue });

	        	var obj={};
	    		obj['filterID']=id;
	    		obj['values']=new Array(newvalue);

	    		this.facets[0].filters.push(obj);

	    	}

	    		this.setFilters();

		    }
		    else{
		    	var findex = -1;
		    	var self=this;
		    	let found=this.facets[0].filters.some(function(item,idx){
					item.filterID=item.filterID.replace(/\./g, '_');
		    		if(item.filterID===id){
	    		    	findex=$.inArray(newvalue, item.values);
	    		    	if(findex!=-1){
	    		    		item.values.splice(findex,1);
	    		    		if(item.values.length==0){
	    		    			//remove filter id and values
	    		    			self.facets[0].filters.splice(idx,1);

	    		    		}

	    		    	    $('#facet_tags').tagsinput('remove', { id: id+'#'+findex, label: newvalue  });
	    		    	}
	    		    	return;
		    		}

		    	});

		    	this.setFilters();
		    }

		 return true;

	    }


	 isChecked(thisfilter,val){

		 let found=this.parent.filterselection.find(function(item){
			  if(item.filterID.replace(/\./g, '_')===thisfilter.filterID.replace(/\./g, '_')){
    		    	return ($.inArray(val, item.values)>-1);
	    		}

		 })
		 if(found){return true;}
		 else{return false;}
	 }


	 tagRemovalListener(){
		var self=this;
	    $('#facet_tags').on('itemRemoved', function(event) {

	    		var id=event.item.id.substring(0,event.item.id.indexOf("#"));
	    		let dup_array = self.facets[0].filters;

	    		var filterfound=dup_array.some(function(item,idx) {
	        	  if(item!=undefined)	{
	        	    if(item.filterID.replace(/\./g, '_')===id.replace(/\./g, '_')){
	    		    	var index=$.inArray(event.item.label, item.values);
	    		    	if(index>-1){
	    		    		var v=$.escapeSelector(event.item.label+"-filter");
	    		    		$("[id='"+v+"']").prop('checked', false);


	    		    		item.values.splice(index,1);
	    		    		if(item.values.length==0){
	    		    			self.facets[0].filters.splice(idx,1);
	    		    		}

	    		    		self.setFilters();
	    		    		return true;
	    		    	}

	    		    }
	        	   }
	    	        return false;

	    		});

	    	});


	 }

	toggleAll(target) {
		var $optionsContainer = $("#" + target);
		var $allOn = true;
		$optionsContainer.find("input[type=checkbox]").each(function() {
			if (!$(this).is(":checked")) $allOn = false;
		});
		$optionsContainer.find("input[type=checkbox]").each(function() {
			$(this).prop('checked', !$allOn)
		});
	}

}
