/* eslint-disable */
import settings from 'global.config.js';
import { inject, TemplatingEngine,TaskQueue,bindable } from 'aurelia-framework';
import { UserServices } from 'UserServices.js';
import { RecordServices } from 'RecordServices.js';
import { SearchServices } from 'SearchServices.js';
import { SpaceServices } from 'SpaceServices.js';
import { Record } from 'Record.js';
import {SearchSource} from 'SearchSource.js';
import {Router} from 'aurelia-router';
import {initTooltip, showMoreLess,initAureliaIsotope, aureliaIsoImagesLoaded,initRows,aureliaSlickLoaded,initSearchSticky,initFilterEvent,tabAction,isoRelay} from 'utils/Plugin.js';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Query } from 'Query.js';
import {JsonUtils} from 'JsonUtils.js';


let instance = null;
let tagsinput = require('bootstrap-tagsinput');

@inject(RecordServices, SearchServices, UserServices, TaskQueue,Router, TemplatingEngine,EventAggregator, SpaceServices)
export class Search2 {


	constructor( recordServices, searchServices, userServices, taskQueue, router, engine,eventAggregator, spaceServices) {
		if (instance) {
			return instance;
		}
		this.engine = engine;
		this.router = router;
		this.recordServices = recordServices;
		this.userServices = userServices;
		this.searchServices=searchServices;
		this.spaceServices = spaceServices;
		this.records = [];
		this.mosaic=false;
		this.sourcesItems=[];
		this.initgrid=false;
		this.countrequests=0;
		this.taskQueue=taskQueue;
		this.query = new Query();
		this.sources=[];
		this.initialFilters=[];
		this.unionFilters=[];
		this.filters=[]; /* all filters coming back from reply*/
		this.filterselection=[] /* user's selection of filters */
		this.selectedRecord = null;
		this.fetchitemnum = 20;
		this.hasImage=true;
		this.loading = false;
		this.thisVM=this;
		this.more = false;
		this.ea = eventAggregator;
        this.term="";
        this.internalSearch=false;
        this.page=1;
		this.sourceResults=[];
		this.spaceOnly = false;
		this.initSources=["Rijksmuseum","The British Library","WITH Collections","Europeana","Digital Public Library of America"];
		if (!instance) {
			instance = this;
		}
		
		this.searchServices.getRightsByCategory("NORIGHTS")
		.then(reply => {
			this.rightsNo=reply;
			return true;

		}).catch(error => {
			self.loading=false;
			toastr.error(error.message);
		});

		this.searchServices.getRightsByCategory("FREE")
		.then(reply => {
			this.rightsFree=reply;
			return true;

		}).catch(error => {
			self.loading=false;
			toastr.error(error.message);
		});
		this.searchServices.getRightsByCategory("LIMITED")
		.then(reply => {
			this.rightsLimited=reply;
			return true;

		}).catch(error => {
			self.loading=false;
			toastr.error(error.message);
		});




	}

	internalSearchChanged(){
		if(!this.internalSearch){
			this.initSources=["Rijksmuseum","The British Library","WITH Collections","Europeana","Digital Public Library of America"];
		}
		else{
			this.initSources=["WITH Collections"];
			this.sourceResults=[];
		}
		this.fetchInitialFilters();
	}

	get space() { return this.spaceServices.active; }

	get InitSources(){
		return this.initSources;
	}


	get Sources(){
		return this.sources;
	}

	
	
	
	attached() {
		$( '.action' ).removeClass( 'active' );
		initAureliaIsotope(this.grid);
		if(this.sourceResults.length>0){
			initRows(false);
		}
		if(this.initialFilters.length==0){
			   this.fetchInitialFilters();
			}
		initSearchSticky();
		tabAction();
	}

	resetInstance() {
		var self=this;
		$("#facet_tags").tagsinput('removeAll');
		if ($( '[data-grid="isotope" ]' ).data('isotope')){
			$( '[data-grid="isotope" ]' ).isotope( 'remove', $(".item"));
			isoRelay();}
		this.records = [];
		this.filters=[];
		this.unionFilters=[];
		this.unionFilters.push.apply(this.unionFilters,this.initialFilters);
		this.filterselection=[];
		this.selectedRecord = null;
		this.page=1;
		this.sourcesItems=[];
		this.sourceResults=[];
		this.loading = false;
		this.more = false;
		this.countrequests=0;
		this.query=new Query();
	}


	resetRecords() {
		if ($( '[data-grid="isotope" ]' ).data('isotope')){
			$( '[data-grid="isotope" ]' ).isotope( 'remove', $(".item"));
			isoRelay();}
		this.records = [];
		this.selectedRecord = null;
		this.page=1;
		this.sourcesItems=[];
		this.sourceResults=[];
		this.loading = false;
		this.more = false;
		this.countrequests=0;
		this.query=new Query();
	}

	activate(params) {
		$('.action').removeClass('active');
		if (this.space != null) {
			this.spaceOnly = true;
			this.internalSearch = true;
		}
		if (params.term !== this.term) {
			this.term = params.term;
			$( 'ul.filtercontent' ).removeClass( 'active' );
			this.ea.publish('filters-reset', {});
			this.resetInstance();
			$(window).scrollTop(0);
			if(params.filters){
				this.deserialize(params.filters);
				//read the filters from the url
			}
			if(params.internal){
				this.internalSearch=(params.internal=="true");
			}
		    if(params.sources && !this.internalSearch){
		    	this.initSources=["Rijksmuseum","The British Library","WITH Collections","Europeana","Digital Public Library of America"];
		    	this.deserializeSources(params.sources);
		    }
		    else if(this.internalSearch){this.initSources=["WITH Collections"];}
		} else {
			this.ea.publish('filters-reset', {});
			this.term = params.term;
		}
       this.init();
	}

	init(){
		if(this.sourceResults.length==0){
			this.getSources();
			this.search();
		}
	}

	getSources(){
		this.searchServices.getSources2()
		.then(response => {
			this.sources=response;
			this.ea.publish('filters-reset', {});
		}).catch(error => {
			console.log(error.message);
		});
	}

	/* initializes available values for filters for joint sources search*/
	initFilters(data,greyd){
		let facets=[];
        for(let f in data){
        	let facet={}
        	facet.filterID=f;
         if(data[f] && data[f].length>0){
        	facet.suggestedValues=data[f];
        	/*replace .in filter name */
        	facet.uiname=JsonUtils.filterName(facet.filterID);
        	console.log(facet.uiname);
        	if(facet.uiname){
        	   facet.uihandle=facet.uiname;
        	   facet.uihandle=facet.uihandle.replace(/\s/g, '');
        	   facet.filterID=facet.filterID.replace(/\./g, '_');

               facets.push(facet);}
         }
        }
        return facets;
        
       
     
	}

	
	
	createUnion(arr1, arr2) {
	    var union = arr1.concat(arr2);

	    for (var i = 0; i < union.length; i++) {
	        for (var j = i+1; j < union.length; j++) {
	        	if(union[i].filterID===union[j].filterID){
	            	this.joinFilters(union[i],union[j]);
	                union.splice(j, 1);
	                j--;
	            }
	        }
	    }

	    return union;
	}

	joinFilters(g1, g2) {
			//concat suggestedValues
		  g1.suggestedValues=g1.suggestedValues.concat(g2.suggestedValues);
		  for (var i = 0; i < g1.suggestedValues.length; i++) {
	        for (var j = i+1; j < g1.suggestedValues.length; j++) {
	            if(g1.suggestedValues[i].value===g1.suggestedValues[j].value){
	            	g1.suggestedValues[i].greydout=false;
	            	g1.suggestedValues.splice(j, 1);
	                j--;
	            }
	            
	        }
	      }
			
		
	}


	

	fetchInitialFilters(){
		var self=this;
		self.initialFilters=[];
		self.unionFilters=[];
		this.searchServices.initialFilters()
		.then(reply => {
			
			self.initialFilters=self.initFilters(reply);
			self.initialFilters.forEach(function(obj) { obj.suggestedValues.forEach(function (val){val.greydout = true; })});
			self.unionFilters=self.initialFilters;
			return true;

		}).catch(error => {
			self.loading=false;
			toastr.error(error.message);
		});
	}
	
	doSearch(event){
		if((event && event.which ==13) || !event) {
			 this.initSearch();
		}

	}


	resetSearch(event){
			if ($( '[data-grid="isotope" ]' ).data('isotope')){
				$( '[data-grid="isotope" ]' ).isotope( 'remove', $(".item"));
				$( '[data-grid="isotope" ]' ).isotope('layout');
				}
			 //change the url
			 this.filterselection=[];
			 this.filters=[];
			 this.fetchInitialFilters();
			 this.router.navigateToRoute('search',  {term: this.term,  internal:this.internalSearch, sources:this.initSources.toString()});
		     //do search

			 $( 'ul.filtercontent' ).removeClass( 'active' );
			 $("#facet_tags").tagsinput('removeAll');
		     this.resetRecords();
			 this.ea.publish('filters-reset', {});

			 this.search();
	}

	initSearch(){

		  if ($( '[data-grid="isotope" ]' ).data('isotope')){
				$( '[data-grid="isotope" ]' ).isotope( 'remove', $(".item"));
				$( '[data-grid="isotope" ]' ).isotope('layout');
				}

		    this.fetchInitialFilters();
			let filterstr=this.serialize(this.filterselection);
			 //change the url
			if(filterstr.length>0){
			 this.router.navigateToRoute('search',  {term: this.term, filters: filterstr, internal:this.internalSearch, sources:this.initSources.toString()});}
			else{
				 this.router.navigateToRoute('search',  {term: this.term,  internal:this.internalSearch, sources:this.initSources.toString()});
			}
		     //do search

		    this.resetRecords();
			this.search();
	}


	serialize(obj) {
		  var str = [];
		  for(var p in obj){
		    if (obj.hasOwnProperty(p) && obj[p].values.length>0) {
		      str.push(obj[p].filterID.replace(/\_/g, '.') + ":" + obj[p].values.toString());
		    }
  		   }
           return str.join("|");
		}


	deserialize(obj){
		var str=obj.split("|");
		/* for every str find the comma separated values*/
		for(var i in str){
			var filterparts=str[i].split(":");
			this.filterselection.push({'filterID': filterparts[0],'values':filterparts[1].split(",")});
		}
	}

	deserializeSources(obj){
		var str=obj.split(",");
		this.initSources=[];
		for(var i in str){
			this.initSources.push(str[i]);
		}
	}


	/* called when filter is selected*/
	filterSearch(){

		/* empty results*/
		this.records=[];
		this.page=1;
		this.sourceResults=[];
		this.sourcesItems=[];
		this.loading = false;
		this.more = false;
		this.filters=[];
		this.unionFilters=[];
		this.unionFilters.push.apply(this.unionFilters,this.initialFilters);
		
		this.query=new Query();
		let filterstr=this.serialize(this.filterselection);
		this.router.navigateToRoute('search', {term: this.term, filters: filterstr, internal:this.internalSearch, sources:this.initSources.toString()});

		this.search();

	}


	nofilterSearch(){
		/* empty results*/
		this.filters=[];
		this.unionFilters=[];
		this.unionFilters.push.apply(this.unionFilters,this.initialFilters);
		
		this.filterselection=[];

		this.records=[];
		this.page=1;
		this.sourceResults=[];
		this.sourcesItems=[];
		this.loading = false;
		this.more = false;
		this.query=new Query();
		this.ea.publish('filters-reset', {});
		//change url
		this.router.navigateToRoute('search', {term: this.term,  internal:this.internalSearch, sources:this.initSources.toString()});
		this.search();

	}

	deactivateFilters(){
		$( 'ul.filterlist li').each(function(){$(this).removeClass('active')});
		$("ul.filtercontent").removeClass('active');
		$("ul.filterlist").removeClass('active');
	}


	switchMosaic(event){
		this.mosaic=!this.mosaic;
		if(this.mosaic){
			$("#mview").css('background-color', '#4f7979');
			$("#mview").css('color', 'white');
			$("#srows").hide();
			$("#mosaic").show();
			if(!this.initgrid){

	            initAureliaIsotope(this.grid);
				if(this.sourcesItems.length>0){

					this.taskQueue.queueTask(() => {
						aureliaIsoImagesLoaded(this.grid, $('.isoload'),this.thisVM);
					});
					this.taskQueue.queueTask(() => {
						initTooltip();
					});
				}
				this.initgrid=true;
			}
			else{

					isoRelay();
					$( '[data-grid="isotope" ]' ).find('.item').removeClass('isoload');


			}

		}
		else{
			$("#mview").css('background-color', 'white');
			$("#mview").css('color', 'grey');

			$('[data-grid="isotope" ]').isotope('destroy');
			if(this.sourceResults.length>0){
				initRows(false);
			}
			$("#mosaic").hide();
			$("#srows").show();
		}
	}

	searchGlobally() {
		this.internalSearch = false;
		this.initSources=["Rijksmuseum","The British Library","WITH Collections","Europeana","Digital Public Library of America"];
		this.spaceOnly = false;
		this.resetInstance();
		this.ea.publish('filters-reset', {});
		this.search();
	}

	search() {
		//no search term, user clicked on search from menu
		//by default we search internal, for now external until backend is complete
		if(!this.internalSearch){
			//search in outside sources
			if(!this.loading && this.term) {
				//search for term, TODO filters

				this.deactivateFilters();
				this.externalSearch();
			}
		}
		else if(!this.loading && this.term) {
			//search for term, TODO filters
			this.deactivateFilters();
			this.withinSearch();
		}
	}


	continuationQuery(){
		let self=this;
		self.searchServices.search2(this.query)
		.then(reply => {
			if (reply.status !== 200) {
				if (reply.statusText) {
					throw new Error(reply.statusText);
				} else if (reply.error) {
					throw new Error(reply.error);
				}
			}
			var handleResponse=self.handleQueryResponse(reply);			
			if(reply.continuationId){
			  self.query=self.query.continueQuery(reply.continuationId);
			  self.continuationQuery();}
			else{
                self.more=false;
				self.loading=false;

			}

		}).catch(error => {
			self.loading=false;
		//	toastr.error(error.message);
		});

	}

	/*search in all external sources*/
	externalSearch(){
		var self=this;
		//console.log(this.filterselection);
		this.loading=true;
		this.more=false;
		this.query=new Query();

       this.initSources.forEach(function (value) {
    	   self.query.addSource(value);
    	 });

		this.query
		  .query( this.term )
		  .startAtPage( this.page )
		  .withPageSize( this.fetchitemnum );
		//this.query.addFacet("media.withRights");
		//this.query.addFacet("media.type");
		this.query.simpleFieldFilter( 'resourceType', true, 'CulturalObject' );
		if (this.hasImage) this.query.simpleFieldFilter( 'hasImage', false, 'true' );

		for(let f in this.filterselection){
			let filter=this.filterselection[f];
			this.query.simpleFieldFilter(filter.filterID,true,...filter.values);
		}


	    this.query.fastResponse();
	    console.log(this.query);
	    this.continuationQuery();
	}


	withinSearch() {
		this.query=new Query();
		var self=this;
		this.loading=true;
		this.more=false;
		this.initSources=["WITH Collections"];
		this.query.addSource("WITH Collections");
		this.query
		  .query( this.term )
		  .startAtPage( this.page )
		  .withPageSize( this.fetchitemnum );
		//this.query.addFacet("media.withRights"); this code does anything
		//this.query.addFacet("media.type");
		this.query.simpleFieldFilter( 'resourceType', true, 'CulturalObject' );
		if (this.hasImage) this.query.simpleFieldFilter( 'hasImage', false, 'true' );

		for(let f in this.filterselection){
			let filter=this.filterselection[f];
			this.query.simpleFieldFilter(filter.filterID,true,...filter.values);
		}
		if (this.spaceOnly) {
		 this.query.simpleFieldFilter('administrative.collectedBy.WRITE', true, this.space.dbId);
	 }
		var res1,res2,res3=0;
	  this.simpleQuery("Items").then(res => {
		  res1=res;
		  self.query.removeSimpleFieldFilter('resourceType');
			if (this.spaceOnly) {
			 	self.query.removeSimpleFieldFilter('administrative.collectedBy.WRITE');
				self.query.simpleFieldFilter('administrative.access.WRITE', true, this.space.dbId);
			}
			if (this.hasImage) self.query.removeSimpleFieldFilter('hasImage');
		  self.query.simpleFieldFilter( 'resourceType', true, 'SimpleCollection' );
		  self.simpleQuery("Collections").then(r => {
			   res2=r;
			   self.query.removeSimpleFieldFilter('resourceType');
		       self.query.simpleFieldFilter( 'resourceType', true, 'Exhibition' );
		       self.simpleQuery('Exhibitions').then(r => {
		    	   self.loading=false;

			       initRows(false);
		    	   res3=r;
		    	   if(res1==self.fetchitemnum || res2==self.fetchitemnum || res3==self.fetchitemnum){
		    		  self.more=true;
		    		  self.page++;
		    	     }
		    	   else{
		    		   self.more=false;

		    		 

		    	   }
		    	   }

		       );


		   })


	   });


	}


	simpleQuery(returnType){
		let self=this;
		return self.searchServices.search2(this.query)
		.then(reply => {

			if (reply.status !== 200) {
				if (reply.statusText) {
					throw new Error(reply.statusText);
				} else if (reply.error) {
					throw new Error(reply.error);
				}
			}
			var numres=self.handleSimpleQueryResponse(reply,returnType);
			return numres;

		}).catch(error => {
			self.loading=false;
			toastr.error(error.message);
		});

	}


	searchMore(){
		this.query
		  .startAtPage( this.page );
		this.more=false;
		this.loading=true;
		if(!this.internalSearch){
			    this.query.fastResponse();
			   // console.log(this.query);
			    this.continuationQuery();
		}
		else{
			this.deactivateFilters();

			this.withinSearch();
		}
	}

	/**
	 * put the results from a query into the UI
	 */
	handleQueryResponse( response ) {
		// console.log( response );
		let self=this;
		let results = response.results;
		let sources_ids = response.sources_ids;
		let accumulatedValues = response.accumulatedValues;
		self.filters=self.initFilters( accumulatedValues);
		self.unionFilters=self.createUnion(self.unionFilters, self.filters);
		
		// one result per source
		for(let i in results ) {
			let singleResponse = results[i];
			let source=singleResponse.source_id;

			let filters = [];
			filters.push( ...singleResponse.facets );
			filters.push( ...singleResponse.counts );
			// lets assume the count works ??
			let sourcedata=singleResponse.items;
			if(sourcedata.length>0){
				if(singleResponse.items.length == self.fetchitemnum)
				    this.more=true;
				let items = [];
				let ss=self.searchServices.supportedSources.filter(function(x){ return x.source==source});
				let api_console="";
				if(ss && ss[0].consoleurl)
				  api_console=ss[0].consoleurl;


				let srcCat=new SearchSource({
						source:source,
						title: source,
						filters:filters,
						items:items,
						consoleurl:api_console,
						moreitems:singleResponse.items.length < self.fetchitemnum ? false : true,
						total:singleResponse.totalCount
					});

				let count=0;
				for(let k in sourcedata){
						let rec=new Record(sourcedata[k]);

						count++;
						if(count<20)
						  srcCat.items.push(rec);
						self.sourcesItems.push(rec);

				}
				var exists=self.sourceResults.filter(sc => (sc.source === source));
				if(exists.length==0){
				  self.sourceResults.push(srcCat);
				}
				if(!this.mosaic){
					self.taskQueue.queueTask(() => {
						aureliaSlickLoaded('.rows .contentitem.'+srcCat.source+srcCat.title, $('.'+srcCat.source+srcCat.title+ ' > .slickload'),false);
						initRows(false);
					});
				}
				else{
					self.taskQueue.queueTask(() => {
						aureliaIsoImagesLoaded(self.grid, $('.isoload'),self.thisVM);
						initTooltip();
					});
				}
			}

		}

	}


	handleSimpleQueryResponse( response,returnType ) {

		let self=this;
		let results = response.results;

		// one result per source

		let singleResponse = results[0];
		let source=singleResponse.source_id;
		let filters = [];
		filters.push( ...singleResponse.facets );
		filters.push( ...singleResponse.counts );
		let sourcedata=singleResponse.items;
		if(sourcedata.length>0){
			    self.more=true;
			    let accumulatedValues = response.accumulatedValues;
			    self.filters=self.initFilters( accumulatedValues);
			    self.unionFilters=self.createUnion(self.initialFilters, self.filters);
				let items = [];
				let ss=self.searchServices.supportedSources.filter(function(x){return x.source==source});
				let api_console="";
				if(ss && ss[0].consoleurl)
				  api_console=ss[0].consoleurl;


				let srcCat=new SearchSource({
						source: source,
						title: returnType,
						filters:filters,
						items:items,
						consoleurl:api_console,
						moreitems:singleResponse.items.length < self.fetchitemnum ? false : true,
						total:singleResponse.totalCount
					});

				let count=0;
				for(let k in sourcedata){
						let rec=new Record(sourcedata[k]);
						if(count<self.fetchitemnum)
							  srcCat.items.push(rec);
					    self.sourcesItems.push(rec);

				}
				var exists=self.sourceResults.filter(sc => (sc.title === returnType));
				if(exists.length==0){
				  self.sourceResults.push(srcCat);
				}
				if(!this.mosaic){
					self.taskQueue.queueTask(() => {
						initRows(false);
						aureliaSlickLoaded('.rows .contentitem.'+srcCat.source+srcCat.title, $('.'+srcCat.source+srcCat.title+ ' > .slickload'),false);
						
					});
				}
				else{
					self.taskQueue.queueTask(() => {
						aureliaIsoImagesLoaded(self.grid, $('.isoload'),self.thisVM);
						initTooltip();
					});
				}
			}

		return sourcedata.length;
	}


	openTab(tabname, arg) {
		this.actionname=tabname;
		$('.action').removeClass('active');

		if(tabname && tabname.title=="Exhibitions"){
			this.selectedRecord = arg;
			this.router.navigateToRoute("exhibitionview",{id: this.selectedRecord.dbId});
		}
		else if(tabname && tabname.title=="Collections"){
			this.selectedRecord = arg;
			this.router.navigateToRoute("collectionview",{id: this.selectedRecord.dbId});
		}
		else{
			this.actionname='item';
			$('.itemview').addClass('active');
			this.selectedRecord = arg;
		}
	}

	filterButtonClick(e){
		e.preventDefault();
	    let elem=e.currentTarget;
	    // get filter id*/
		let filterid = $(elem).attr( 'data-filter' );
        if(!$( '#'+filterid ).hasClass( 'active' )){

		var $self = $( this );

		// toggle

		 $( '.searchbox-content ul' ).each( function(){
		    	if( $(this).hasClass( 'active') && $( this ).has( e.target ).length === 0 &&  !$( this ).is( e.target )) {
		    		$( this ).removeClass( 'active');
		    	}
		    });

		    $( '.filterlist li' ).each( function(){
		    	if( $(this).hasClass( 'active')) {
		    		$( this ).removeClass( 'active');
		    	}
		    });


		    var container = $( '#'+filterid ),
		    	toggleNav = $self;

		    // if the target of the click isn't the container, nor a descendant of the container
		    if  ( !container.is( e.target ) && container.has( e.target ).length === 0 && !toggleNav.is( e.target ))
		    {
		        container.removeClass( 'active' );
		        $self.parent().removeClass( 'active' );
		    }
        }

		$( '#'+filterid ).toggleClass( 'active' );
		$(elem).parent().toggleClass( 'active' );
		if(filterid=="filter-extra" && !$( '#'+filterid ).hasClass( 'active' )){
			$("ul[id^='filter-']:not(:first)").removeClass('active');
			$( 'ul#filter-extra li').each(function(){$(this).removeClass('active')});
		}



	}

	detached()
	{
		$('#facet_tags').off();
		$('#facet_tags').tagsinput('destroy');
		$('.grid').find('.item').addClass('isoload');

	  }


}
