import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

@inject(DialogController)
export class Dialog {

	constructor(controller) {
		this.controller = controller;
	}

	activate(model) {
		if (typeof(model.type) !== 'undefined') {
			switch (model.type) {
			case 'COLLECTION':
				this.title = 'Delete collection?';
				this.message = `Are you sure you want to delete the collection '${model.title}'`;
				break;
			case 'EXHIBITION':
				this.title = 'Delete exhibition?';
				this.message = `Are you sure you want to delete the exhibition '${model.title}'`;
				break;
			case 'CAMPAIGN':
				this.title = 'Delete campaign?';
				this.message = `Are you sure you want to delete the campaign '${model.title}'`;
				break;
			case 'Organization':
				this.title = 'Delete organization?';
				this.message = `Are you sure you want to delete the organization '${model.name}'`;
				break;
			case 'Project':
				this.title = 'Delete project?';
				this.message = `Are you sure you want to delete the project '${model.name}'`;
				break;
			case 'double':
				this.title = 'Create duplicate?';
				this.message = `Item already exists in collection '${model.title}'. Do you want to add it again?`;
				break;
			case 'removeitem':
				this.title = 'Remove Item?';
				this.message = 'Are you sure you want to remove this item from this collection?';
				break;
			default:
				this.title = 'Delete?';
				this.message = 'Are you sure you want to delete this?';
			}
		} else {	// For cases where no model.type exists
			this.title = 'Delete?';
			this.message = 'Are you sure you want to delete this?';
		}
	}
}
