/* eslint-disable */
import { inject } from 'aurelia-framework';
import { HttpClient, json } from 'aurelia-fetch-client';
import fetchConfig from 'fetch.config.js';
import { checkStatus } from 'fetch.config.js';
import { Query } from 'Query.js';
import {SearchSource} from 'SearchSource.js';


@inject(HttpClient)
export class SearchServices {

	constructor(http) {
		http.configure(fetchConfig);
		this.http = http;
		this.supportedSources=[];
	}

	search(term, page, pageSize, source, filters) {
		console.log(JSON.stringify({ searchTerm: term, page: page, pageSize: pageSize, source: source, filters: filters }));
		return this.http.fetch('/api/advancedsearch', {
			method: 'POST',
			body: json({ searchTerm: term, page: page, pageSize: pageSize, source: source, filters: filters }),
			headers: {
				'Content-Type': 'application/json'
				// More options
			}
		}).then(checkStatus).then((response) => response.json());
	}

	getSources() {
		return this.http.fetch('/api/searchsources', {
			method: 'GET'
		})
		.then((response) => response.json())
		.then((allSources) => allSources.map( (source) => source.name));
	}

	getRightsByCategory(category) {
		return this.http.fetch('/api/getrightsbycategory?category='+category, {
			method: 'GET'
		})
		.then((response) => response.json());
	}

	getSources2() {
		let self=this;
		return this.http.fetch('/api/searchsources', {
			method: 'GET',
			async:false
		}).then((response) => response.json())
		.then((allSources) => {
			self.supportedSources = allSources.map( (source) => new SearchSource({source: source.id, title: source.name, consoleurl: source.apiConsole, supportedFields: source.supportedFields}));

			return allSources.map( (source) => source.id);
		} );
	}


	findSupportedSource(sourcename){

		return this.supportedSources.find(x => x.source==sourcename);


	}
	
	
	initialFilters(source) {
		
		
		var buildurl="?source=ALL";
		if(source){
			buildurl="?source="+source;
		}
		return this.http.fetch('/api/initialfilters'+buildurl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
				// More options
			}
		}).then((response) => response.json());
	}
	

	/**
	 * use a query object to make a search request to WITH api
	 * The response is slightly differently shaped than with original API.
	 */
	search2(query) {
		console.log(JSON.stringify(query));
//		window.alert(JSON.stringify(query));
		return this.http.fetch('/api/search2', {
			method: 'POST',
			body: json(query),
			headers: {
				'Content-Type': 'application/json'
				// More options
			}
		}).then(checkStatus).then((response) => response.json());
	}

	relatedsearch(id, size) {
		let similarsQuery = {
			recordId : id,
			size : size
		}
		console.log(JSON.stringify(similarsQuery));
		return this.http.fetch('/api/relatedsearch', {
			method: 'POST',
			body: json(similarsQuery),
			headers: {
				'Content-Type': 'application/json'
				// More options
			}
		}).then(checkStatus).then((response) => response.json());
	}


	searchPublicCollections(query) {
		console.log(JSON.stringify(query));
		return this.http.fetch('/api/search2', {
			method: 'POST',
			body: json(query),
			headers: {
				'Content-Type': 'application/json'
				// More options
			}
		}).then(checkStatus).then((response) => response.json());

	}

}
