/* eslint-disable */
import { inject} from 'aurelia-dependency-injection';
import { UserServices} from 'UserServices.js';
import { Collection} from 'Collection.js';
import { CollectionServices} from 'CollectionServices.js';
import { RecordServices} from 'RecordServices.js';
import { Record } from 'Record.js';
import { ValidationRules } from 'aurelia-validation';
import { DialogService} from 'aurelia-dialog';
import { EventAggregator} from 'aurelia-event-aggregator';
import { PLATFORM } from 'aurelia-pal';


let selectize = require('selectize');

@inject(UserServices, CollectionServices, DialogService, EventAggregator)
export class Collect {

	constructor(userServices, collectionServices, dialogService, eventAggregator) {
		console.log('constructor called');
		this.userServices = userServices;
		this.collectionServices = collectionServices;
		this.dialogService = dialogService;
		this.collections = [];
		this.noCollection = true;
		this.loading = false;
		this.collectionlimit = 1000;
		this.activeRequests = 0;
		this.selected_items = [];

		ValidationRules
			.ensure('title').required().minLength(3).maxLength(30)
			.ensure('thumbnail').required()
			.on(Record);

		if (this.userServices.current) {
			if (!this.userServices.current.editables.length > 0) {
				this.noCollection = false;
			}
		}
		this.ea = eventAggregator;
		this.errors = [];
	}

	get noSavedCollection() {
		if (this.userServices.isAuthenticated()) {
			return !(this.userServices.current.editables.length > 0);
		}

		return true;
	}

	attached() {
		$('.action').removeClass('active');
	}

	closeTab() {
		if (this.activeRequests === 0) {
			$('.action').removeClass('active');
			let $select = $('#selectizeObj').selectize()[0].selectize;
			$select.clear();
			$select.destroy();
			this.parent.selectedRecord = null;
		}
	}

	activate(params) {
		this.selected = params.record;
		this.parent = params.parent;
		if (this.userServices.current) {
			this.getUserCollections();
		}
	}

	detached() {
		if (this.observer) {
			this.observer.dispose();
		}
	}

	get hasErrors() {
		return !!this.errors.length;
	}

	// Functions
	parseErrors(result) {
		this.errors.splice(0, this.errors.length);
		result.forEach(error => {
			this.errors.push(error);
		});
	}

	selectizeInit() {
		$('#selectizeObj').selectize({
			valueField: 'id',
			labelField: 'title',
			searchField: ['title'],
			maxItems: 100,
			options: this.userServices.current.editables,
			create: true,
			closeAfterSelect: true,
			persist: false
		});
	}

	getUserCollections() {
		let self = this;
		if (this.userServices.current.editables.length > 0) {
			this.selectizeInit();
			this.resetSelection();
			return this.userServices.current.editables;
		}

		this.collectionServices.getEditableCollections(0, this.collectionlimit, this.userServices.current.username)
			.then(response => {
				let allcollections = response.collectionsOrExhibitions;
				let temparray = [];
				allcollections.forEach((item) => {
					temparray.push({
						title: (item.descriptiveData.label.default),
						id: item.dbId
					});
				});
				if (temparray.length > 0) {
					this.noCollection = false;
					self.userServices.current.editables.push
						.apply(self.userServices.current.editables, temparray);
					self.selectizeInit();
				}
				return self.userServices.current.editables;
			}).catch(error => {
				console.log(error.message);
			});
	}

	addDescription(event) {
		event.preventDefault();
		$('textarea').show();
		$(event.target).parent('a').hide();
	}

	resetSelection() {
		if (this.selected_items.length > 0) {
			let $select = $('#selectizeObj').selectize()[0].selectize;
			this.selected_items.forEach((item) => {
				$select.addItem(item);
			});
		}
	}

	addToCollections() {
		let self = this;
		this.selected_items = $('#selectizeObj').selectize()[0].selectize.getValue();
		/* now find if item is one of collection ids*/
		this.selected_items.forEach((collection) => {
			if ($.inArray(collection, self.userServices.current.editables.map((x) => {
				return x.id;
			})) !== -1) {
				/* add item to collection with this id*/
				self.activeRequests++;
				self.addRecord(collection);
			} else {
				/*otherwise save this collection and then add the item*/
				let neweditable = {
					resourceType: 'SimpleCollection',
					administrative: {
						access: {
							isPublic: false
						}
					},
					descriptiveData: {
						label: {
							default: [collection],
							en: [collection]
						}
					}
				};
				console.log(neweditable);
				self.activeRequests++;
				self.saveCollection(neweditable, self.addRecord);
			}
		});
	}

	addRecord(collectionId, noDouble) {
		let self = this;
		self.loading = true;
		if (noDouble === undefined) {
			noDouble = true;
		}
		this.collectionServices.addRecord(this.selected, collectionId, noDouble)
			.then(response => {
				self.loading = false;

				if(response.status!="200"){
					if(response.statusText)
				       throw({"message": response.statusText});
					else if(response.error)
					   throw({"message": response.error});
			    }

				self.activeRequests--;
				if (window.location.href.indexOf('/collection/' + collectionId) !== -1) {
					// show the new data in the collection

					self.parent.reloadCollectionDetails();
					//self.parent.loadNext();
				}

				this.ea.publish('collection-itemsupdated', {
					dbId: collectionId,
					added: true,
					item: this.selected
				});
				self.closeTab();
				toastr.success('Item added successfully!');
			}).catch(error => {
				self.loading = false;

				if (error.message === 'double') {
					let collnameIndex = $.inArray(collectionId, self.userServices.current.editables.map((x) => {
						return x.id;
					}));
					let coll = self.userServices.current.editables[collnameIndex];
					self.dialogService.open({
						viewModel: PLATFORM.moduleName('widgets/dialog/dialog'),
						model: {
							type: 'double',
							title: coll.title
						}, lock: false

					}).whenClosed(reply => {
						if (!reply.wasCancelled) {
							self.addRecord(collectionId, false);
						} else {
							self.activeRequests--;

							self.closeTab();
						}
					});
				} else {
					self.activeRequests--;

					toastr.error(error.message);
				}
			});
	}

	saveCollection(coll, callback) {
		//let self = this;
		this.loading = true;

		this.collectionServices.save(coll)
			.then(response => {
				this.loading = false;
				if (response.status !== 200) {
					if (response.statusText) {
						throw new Error({
							'message': response.statusText
						});
					} else if (response.error) {
						throw new Error({
							'message': response.error
						});
					}
				}
				if (!this.userServices.current) {
					toastr.error('An error has occurred. You are no longer logged in!');
					this.activeRequests--;
					return;
				}

				this.ea.publish('collection-created', new Collection(response));

				let $select = $('#selectizeObj').selectize()[0].selectize;
				$select.addOption({
					id: response.dbId,
					title: response.descriptiveData.label.default
				});
				$select.removeItem(response.descriptiveData.label.default[0]);
				$select.refreshOptions();
				$select.addItem(response.dbId);

				if (callback) {
					callback.call(this, response.dbId);
				} else {
					this.activeRequests--;
					return;
				}
			}).catch(error => {
				this.loading = false;
				this.activeRequests--;
				toastr.error(error.message);
			});
	}
}
