import { PLATFORM, FrameworkConfiguration}from "aurelia-framework";
import numeral from 'numeral';

export function configure(aurelia) {
	aurelia.globalResources(PLATFORM.moduleName("./fromnow"),PLATFORM.moduleName("./dateformat"));
	aurelia.globalResources(PLATFORM.moduleName("./sortcollections"));
	aurelia.globalResources(PLATFORM.moduleName('./itemscounter'), PLATFORM.moduleName('./collectionscounter'), PLATFORM.moduleName('./exhibitionscounter'), PLATFORM.moduleName('./organizationscounter'));
	aurelia.globalResources(PLATFORM.moduleName('./projectscounter'), PLATFORM.moduleName('./groupscounter'), PLATFORM.moduleName('./annotationscounter'));
	aurelia.globalResources(PLATFORM.moduleName('./campaignscounter'), PLATFORM.moduleName('./campaignscounter'), PLATFORM.moduleName('./campaignscounter'));
	aurelia.globalResources(PLATFORM.moduleName('./notificationmessage'));
	aurelia.globalResources(PLATFORM.moduleName('./filestoarray'));
	aurelia.globalResources(PLATFORM.moduleName('./withurl'));
	aurelia.globalResources(PLATFORM.moduleName('./useravatar'));
	aurelia.globalResources(PLATFORM.moduleName('./highlighter'));
	aurelia.globalResources(PLATFORM.moduleName('./logo'), PLATFORM.moduleName('./cover'), PLATFORM.moduleName('./background'));

}

// Helper function to be used in counter value converters
export function pluralFormatter(value, string) {
	if (value !== 1) {
		string += 's';
	}

	return numeral(value).format('0,0') + ` ${string}`;
}
