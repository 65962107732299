/*eslint-disable no-var*/
var settings = {
  space: 'with',
  auth: {
    google: '104762619694-41avdtg6stm15vrgv511fa1spb6m8282.apps.googleusercontent.com',
    facebook: '132619063927809'
  },
  baseUrl: 'https://api.withculture.eu',
  apiUrl: '/assets/developers-lite.html',
  googlekey: 'AIzaSyAdgFjsGN9VmFLGQlAdRYaz0REwyvjn6eg',
  isoSettings: {
    // page
    page: 'default',

    // masonry
    mSelector: '.grid',
    mItem: '.item',
    mSizer: '.sizer',

    // mobile menu
    mobileSelector: '.mobilemenu',
    mobileMenu: '.main .menu',
    transDuration: 0
  },
  logLevel: 1 // Error: 1, Warn: 2, Info: 3, Debug: 4
};

// console.log(window.location.hostname);
// Override settings for development/testing etc
if (window.location.hostname === 'localhost') {
  settings.auth.facebook = '133438360512546';
  // settings.baseUrl = 'http://localhost:9060';
  settings.logLevel = 4; // Debug
} else if (window.location.hostname === 'ipa.image.ntua.gr') { // Override for staging
  settings.baseUrl = 'http://ipa.image.ntua.gr:9030';
} else if (window.location.hostname === 'www.fifties.withculture.eu' || window.location.hostname === 'fifties.withculture.eu') {
  settings.redirectToSpace = true;
  settings.spaceName = 'fifties';
  settings.spacePageName = '';
} else if (window.location.hostname === 'www.pagode.withculture.eu' || window.location.hostname === 'pagode.withculture.eu') {
  settings.redirectToSpace = true;
  settings.spaceName = 'photography';
  settings.spacePageName = '/pagode';
} else if (window.location.hostname === 'withculture.eu' || window.location.hostname === 'www.withculture.eu') {
  settings.auth.facebook = '394384180936771';
  settings.baseUrl = 'https://api.withculture.eu'
} else if (window.location.hostname === 'https://withculture.eu' || window.location.hostname === 'https://www.withculture.eu') {
  settings.auth.facebook = '394384180936771';
  settings.baseUrl = 'https://api.withculture.eu'
} else if (window.location.hostname === 'judaica.withculture.eu') {
  settings.space = 'judaica';
  settings.baseUrl = 'http://api.withculture.eu';
} else if (window.location.hostname === 'with.image.ntua.gr' || window.location.hostname === 'with.image.ece.ntua.gr') {
  settings.baseUrl = 'http://api.withculture.eu';
} else if (window.location.hostname === 'espaceportal.eu' || window.location.hostname === 'www.espaceportal.eu') {
  settings.auth.facebook = '132619063927809';
  settings.baseUrl = 'http://api.espaceportal.eu';
} else {
  console.log(`${window.location.hostname}`);
}

export default settings;
