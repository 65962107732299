import { AuthenticateStep,AuthService } from 'aurelia-authentication';
import { inject,PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SpacesStep } from 'utils/spacesstep.js';
import cnf from 'global.config.js';
import settings from 'global.config.js';

@inject(Router)
export default class {
	constructor(router) {
		this.router = router;
		this.router.pipelineProvider.steps.splice(2, 0, AuthenticateStep); // Only allow access to routes with "auth: true" to logged in users. To everyone else, it redirects them to the login page
		// before the space constructor, thus everything is initialized correctly
		this.router.pipelineProvider.steps.splice(2, 0, SpacesStep);
	}

	configure() {
		const appRouterConfig = function(config) {
			config.title = 'WITH';
			config.addPipelineStep('postcomplete', PostCompleteStep);

			config.options.pushState = true;					// Removes the hash (#) from the urls. REQUIRES TO BE ENABLED ON THE SERVER!
			//add the econd part for moduleName to create chunks for app
			config.map([
				{ route: ['searchwithin'],  name: 'searchwithin',            moduleId: PLATFORM.moduleName('pages/searchwithin/searchwithin','searchwithin'),            nav: false, title: 'Explore'              },
				{ route: ['explore', 'search'],  name: 'search',            moduleId: PLATFORM.moduleName('pages/search2/search2','search'),            nav: false, title: 'Explore'              },
				{ route: ['collection/:id'],     name: 'collectionview',    moduleId: PLATFORM.moduleName('pages/collectionview/collectionview-ext','collection'),   nav: false, title: 'Collection'              },
				{ route: ['exhibition/:id'],     name: 'exhibitionview',    moduleId: PLATFORM.moduleName('pages/exhibitionview/exhibitionview','exhibition'),   nav: false, title: 'Exhibition'              },
				{ route: ['item/:id'],           name: 'itemview',          moduleId: PLATFORM.moduleName('pages/itemview/itemview','item'),        nav: false, title: 'Item'              },
				{ route: ['#!item/:id'],         name: 'itemview',          moduleId: PLATFORM.moduleName('pages/itemview/itemview','item'),        nav: false, title: 'Item'              },
				{ route: 'organization/:id',     name: 'organizationview',  moduleId: PLATFORM.moduleName('pages/organization/organization-ext','orgext'), nav: false, title: 'Group'    },
				{ route: 'group/:id',            name: 'group',             moduleId: PLATFORM.moduleName('pages/organization/organization-ext','groupext'), nav: false, title: 'Group'    },
				{ route: ['search'],             name: 'search',            moduleId: PLATFORM.moduleName('pages/search2/search2'),            nav: false, title: 'Search'              },
				{ route: ['searchsource'],       name: 'searchsource',      moduleId: PLATFORM.moduleName('pages/searchsource/searchsource','searchsource'),            nav: false, title: 'Search source'              },
				{ route: 'searchcollections',       name: 'searchcollections',      moduleId: PLATFORM.moduleName('pages/searchcollections/searchcollections','searchcollections'),            nav: false, title: 'Search collections'              },
				{ route: 'searchexhibitions',       name: 'searchexhibitions',      moduleId: PLATFORM.moduleName('pages/searchexhibitions/searchexhibitions','searchexhibitions'),            nav: false, title: 'Search exhibitions'              },
				{ route: 'about',                name: 'about',             moduleId: PLATFORM.moduleName('pages/about/about','about'),              nav: false,  title: 'About'                },
				{ route: 'aboutspace',                name: 'about espace',             moduleId: PLATFORM.moduleName('pages/aboutespace/about','aboutspace'),              nav: false,  title: 'About'                },
				{ route: 'api',                  name: 'api',               redirect: `${cnf.baseUrl}${cnf.apiUrl}`,       nav: false, title: 'WITH API'             },
				{ route: 'privacy',              name: 'privacy',           moduleId: PLATFORM.moduleName('pages/privacy/privacy','privacy'),          nav: false, title: 'Privacy Policy'       },
				{ route: 'terms',                name: 'terms',             moduleId: PLATFORM.moduleName('pages/terms/terms','terms'),              nav: false, title: 'Terms and Conditions' },
				{ route: 'feedback',             name: 'feedback',          moduleId: PLATFORM.moduleName('pages/feedback/feedback','feedback'),        nav: false, title: 'Feedback & Contact'   },
				{ route: 'login',                name: 'login',             moduleId: PLATFORM.moduleName('pages/login/login','login'),              nav: false, title: 'Sign In',             authRoute: true },
				{ route: 'register',             name: 'register',          moduleId: PLATFORM.moduleName('pages/register/register','register'),        nav: false, title: 'Register',            authRoute: true },
				{ route: ['dashboard', 'my'],    name: 'dashboard',         moduleId: PLATFORM.moduleName('pages/my/my','my'),                    nav: false, title: '', auth: true },
				{ route: 'custom/:name',         name: 'custom',            moduleId: PLATFORM.moduleName('pages/space/space','space'),              nav: false, title: '' },
				{ route: 'externallogin',        name: 'externallogin',     moduleId: PLATFORM.moduleName('widgets/externallogin/externallogin','extlogin'), nav: false, title: 'Login to WITH' },
				{ route: 'spaces',							 name: 'spaces',						moduleId: PLATFORM.moduleName('pages/providers/providers','providers'), 		 nav: false, title: 'All Spaces'},
				{ route: 'sources',              name: 'sources',           moduleId: PLATFORM.moduleName('pages/sources/sources','sources'),          nav: false,  title: 'Sources'},
				{
					route: 'mupop/:id',          name: 'popexhibition',   moduleId: PLATFORM.moduleName('pages/popexhibition/popexhibition','popexhbition'), nav: false, title: 'Exhibition'
				}
			]);

			if (settings.redirectToSpace) {
				config.map([{route: 'home', name: 'home', moduleId: PLATFORM.moduleName('pages/space/space','space')}]);
				config.map([{route: '', name: '', moduleId: PLATFORM.moduleName('pages/space/space','space')}]);
			} else {
				config.map([{ route: ['', 'home'], name: 'home', moduleId: PLATFORM.moduleName('pages/home/home','home'), nav: true, title: 'Home'}]);
			}
		};

		this.router.configure(appRouterConfig);
	}

}

class PostCompleteStep {
	run(routingContext, next) {
		if (routingContext.fragment.indexOf('/my') === 0 ||
		    routingContext.fragment.indexOf('/login') === 0 ||
			routingContext.fragment.indexOf('/register') === 0 ||
			routingContext.fragment.indexOf('/externallogin') === 0
		) {
			$('body').addClass('dashboard');
			$('body').show();
		} else {
			$('body').removeClass('dashboard');
		}
		$(window).scrollTop(0);
		return next();
	}
}
