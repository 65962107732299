/* eslint-disable */
export class MapUtils {

  static coordinates2degrees(lon, lat) {
      var deg = Math.floor(Math.abs(lon));
      var dec = (Math.abs(lon) - Math.floor(Math.abs(lon)))*60;
      var min = Math.floor(dec);
      if (min < 10) {
        min = `0${min}`;
      }
      dec = Math.floor((dec - Math.floor(dec))*60);
      if (dec < 10) {
        dec = `0${dec}`;
      }

      var lonSt = `${deg}° ${min}' ${dec}"`;
      if (lon >= 0) {
        lonSt = lonSt + ' E';
      } else {
        lonSt = lonSt + ' W';
      }

      deg = Math.floor(Math.abs(lat));
      dec = (Math.abs(lat) - Math.floor(Math.abs(lat)))*60;
      min = Math.floor(dec);
      if (min < 10) {
        min = `0${min}`;
      }
      dec = Math.floor((dec - Math.floor(dec))*60);
      if (dec < 10) {
        dec = `0${dec}`;
      }

      var latSt = `${deg}° ${min}' ${dec}"`;
      if (lat >= 0) {
        latSt = latSt + ' N';
      } else {
        latSt = latSt + ' S';
      }
      return latSt + "  " + lonSt;
    }
}
