/* eslint-disable */
import { customAttribute, inject } from 'aurelia-framework';

@inject(Element)
@customAttribute('return-trigger')
export class ReturnCustomAttribute {
  constructor(element) {
    this.element = element;
  }

  attached() {
    this.element.addEventListener('keyup', e => {
      if (e.which == 13) {
        var event = new CustomEvent('return', { bubbles: true });
        this.element.dispatchEvent(event);
      }

      if (e.which == 27) {
        var event = new CustomEvent('escape', { bubbles: true });
        this.element.dispatchEvent(event);
      }
    });
  }
}
