import settings from 'global.config.js';

// See: https://github.com/github/fetch#handling-http-error-statuses
export function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}

	let error = new Error(response.statusText);
	error.response = response;
	throw error;
}

function fetchConfigDevelopment(config) {
	config
		.withBaseUrl(settings.baseUrl)
		.withDefaults({
			credentials: 'include',
			headers: {
				'Accept': 'application/json'
			}
		// })
		// .withInterceptor({
		// 	request(request) {
		// 		console.log(`Requesting ${request.method} ${request.url}`);
		// 		return request; // you can return a modified Request, or you can short-circuit the request by returning a Response
		// 	},
		// 	response(response) {
		// 		console.log(`Received ${response.status} ${response.url}`);
		// 		return response; // you can return a modified Response
		// 	}
		});
}

function fetchConfigProduction(config) {
	config
		.withBaseUrl(settings.baseUrl)
		.withDefaults({
			credentials: 'include',
			headers: {
				'Accept': 'application/json'
			}
		});
}

let config;

if (window.location.hostname === 'localhost') {
	config = fetchConfigDevelopment;
} else {
	config = fetchConfigProduction;
}
export default config;
