import { inject, NewInstance } from 'aurelia-dependency-injection';
import { UserServices } from 'UserServices.js';
import { Collection } from 'Collection.js';
import { CollectionServices } from 'CollectionServices.js';
import { ValidationController, validateTrigger, ValidationRules } from 'aurelia-validation';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

@inject(UserServices, CollectionServices, EventAggregator, NewInstance.of(ValidationController), Router)
export class Newcollection {

	constructor(userServices, collectionServices, eventAggregator, validationController, router) {
		this.userServices = userServices;
		this.collectionServices = collectionServices;
		this.ea = eventAggregator;
		this.validationController = validationController;
		this.router = router;
		this.validationController.validateTrigger = validateTrigger.manual;	// Errors will appear (by default) manually, when the user tries to update
		this.collections = [];
		this.noCollection = true;
		this.saving = false;
		this.showdesc = false;
		this.resourceType = 'collection';
		this.type = 'new';
		this.collection = new Collection();
		this.errors = [];
	}

	get title() {
		switch (this.type) {
		case 'edit':
			return 'Update Collection';
		case 'new':
		case 'new_exhibition':
		default:
			return `New ${this.resourceType}`;
		}
	}

	attached() {
		$('.action').removeClass('active');
		if (this.resourceType === 'exhibition') {
			this.saveButton.innerHTML = 'Create and go to editor';
		}
	}

	closeTab() {
		this.validationController.reset();
		$('.action').removeClass('active');
	}

	activate(params) {
		this.selected = params.record;
		this.parent = params.parent;
		this.type = params.type;	//new collection or update collection
		if (this.type === 'edit') {
			if (typeof params.collection === 'undefined') {
				this.collection = $.extend(true, {}, this.parent.collection);
			} else {
				this.collection = $.extend(true, {}, params.collection);
			}
			this.showdesc = this.collection.hasDescription;
		} else {
			if (this.type === 'new_exhibition') {
				this.resourceType = 'exhibition';
			}
			this.collection = new Collection();
		}

		ValidationRules
			.ensure('title').required()
			.on(this.collection);
	}

	get hasErrors() { return !!this.errors.length; }

	// Functions
	addDescription(event) {
		event.preventDefault();
		this.showdesc = true;
	}

	changePublic() {
		this.collection.isPublic = !this.collection.isPublic;
	}

	addRecord(collectionId, noDouble) {
		let self = this;
		self.saving = true;
		if (noDouble === undefined) {
			noDouble = false;
		}
		this.collectionServices.addRecord(this.selected, collectionId, noDouble)
			.then(response => {
				self.saving = false;

				if (response.status !== 200) {
					if (response.statusText) {
						throw new Error(response.statusText);
					} else if (response.error) {
						throw new Error(response.error);
					}
				}
				if (window.location.href.indexOf('/collection/' + collectionId) !== -1) {
					// show the new data in the collection
					self.parent.loadCollection();
					self.parent.loadNext();
				}
				this.ea.publish('collection-itemsupdated', { dbId: collectionId, added: true, item: this.selected });
				self.closeTab();
				toastr.success('Item added successfully!');
			}).catch(error => {
				self.saving = false;
				toastr.error(error.message);
			});
	}

	save() {
		if (this.type === 'edit') {
			this.updateCollection();
		} else {
			this.saveCollection();
		}
	}

	saveCollection() {
		this.validationController.validate().then(v => {
			if (v.valid) {
				this.saving = true;
				let collectiontosave = {
					resourceType: this.resourceType === 'collection' ? 'SimpleCollection' : 'Exhibition',
					administrative: {
						access: {
							isPublic: this.collection.isPublic
						}
					},
					descriptiveData: {
						label: {
							default: [this.collection.title]
						},
						description: {
							default: [this.collection.description]
						}
					}
				};

				this.collectionServices.save(collectiontosave)
					.then(response => {
						this.saving = false;
						if (response.status !== 200) {
							if (response.statusText) {
								throw new Error(response.statusText);
							} else if (response.error) {
								throw new Error(response.error);
							}
						}

						if (!this.userServices.current) {
							toastr.error('An error has occurred. You are no longer logged in!');
							return;
						}

						/* change editables and user collections */
						if (this.resourceType === 'collection') {
							this.ea.publish('collection-created', new Collection(response));
							toastr.success('Collection saved successfully!');
						} else {
							this.ea.publish('exhibition-created', new Collection(response));
							toastr.success('Exhibition saved successfully!');
							this.router.navigate('/my/exhibition/' + response.dbId + '/edit');
						}
						if (this.selected) {
							this.addRecord.call(this, response.dbId);
						} else {
							this.closeTab();
						}
					}).catch(error => {
						this.saving = false;
						toastr.error(error.message);
					});
			} else {
				this.errors = this.validationController.errors;
			}
		});
	}

	updateCollection = () => {
		// this.errors = this.validationController.validate(this.collection);
		this.validationController.validate().then(v => {
			if (v.valid) {
				this.saving = true;

				let collectiontosave = {
					resourceType: 'SimpleCollection',
					administrative: {
						access: {
							isPublic: this.collection.isPublic
						}
					},
					descriptiveData: {
						label: {
							default: [this.collection.title]
						},
						description: {
							default: [this.collection.description]
						}
					}
				};
				this.collectionServices.update(this.collection.dbId, collectiontosave)
					.then(response => {
						if(response.status !== 200) {
							if (response.statusText) {
								throw new Error(response.statusText);
							} else if (response.error) {
								throw new Error(response.error);
							}
						}
						this.ea.publish('collection-updated', this.collection);
						if (typeof this.parent.collection !== 'undefined') {
							this.parent.collection.title = this.collection.title;
							this.parent.collection.description = this.collection.description;
							this.parent.collection.isPublic = this.collection.isPublic;
						}
						$('.action').removeClass('active');
						this.saving = false;
						toastr.success('Collection updated');
						this.closeTab();
					})
					.catch(error => {
						this.saving = false;
						this.closeTab();
						toastr.error(error.message);
					});
			} else {
				this.errors = this.validationController.errors;
			}
		});
	}
}
