/* eslint-disable */
import settings from 'global.config.js';
import { inject} from 'aurelia-framework';
import { UserServices } from 'UserServices.js';
import { CollectionServices } from 'CollectionServices.js';
import { RecordServices } from 'RecordServices.js';
import { Record } from 'Record.js';
import {Router} from 'aurelia-router';
import {initTooltip,showMoreLess,tabAction,changeTooltipTitle} from 'utils/Plugin.js';
import { AnnotationServices } from 'AnnotationServices.js';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SpaceServices } from 'SpaceServices.js';
import { Query } from 'Query.js';
import {JsonUtils} from 'JsonUtils.js';
import { SearchServices } from 'SearchServices.js';
import { Map,layer as olLayer,source,View,control,coordinate,proj,Feature,geom,style } from 'openlayers';
import { bindable } from 'aurelia-framework';
import { ThesaurusServices } from 'ThesaurusServices.js';
import { SelectedVocabulary } from 'SelectedVocabulary.js';
import { MapUtils } from 'MapUtils.js';

@inject(CollectionServices, RecordServices, UserServices, AnnotationServices, EventAggregator, Router, SpaceServices, SearchServices, ThesaurusServices, SelectedVocabulary)
export class Itemview {

	@bindable prefix = '';

	constructor(collectionServices, recordServices, userServices, annotationServices, eventAggregator, router, spaceServices, searchServices, thesaurusServices, selectedVocabulary) {
		this.router = router;
		this.collectionServices = collectionServices;
		this.recordServices = recordServices;
		this.userServices = userServices;
		this.annotationServices = annotationServices;
		this.spaceServices = spaceServices;
		this.thesaurusServices = thesaurusServices;
		this.record = new Record();
		this.related=[];
		this.relatedItems = {};
		this.similar=[];
		this.loading=false;
		this.disqusLoaded=false;
		this.annotations = [];
		this.vocLevel = new AnnotationLevel();
		this.geoLevel = new AnnotationLevel();
		this.annotationShow = 2;
		this.annotationGrouping = 1;
		this.annotationStatusChange = false;
		this.geoMap = null;
		this.geoMode = 2;
		this.actions = [];
		this.geoSelected = [];
		this.showGeoCoordinates = false;
		this.geoLayers = [];
		this.regrouping = false;
		this.searchServices=searchServices;
		this.ea = eventAggregator;

		this.suggestionsLoading=false;
		this.vocabularies = annotationServices.vocabularies;
		this.suggestedAnnotations =  [];
		this.selectedAnnotation = null;
		this.selvocabularies = [];
		this.selmanualvocabularies = selectedVocabulary.vocabularies;
		this.evsubscr1 = this.ea.subscribe('annotations-created', () => {this.annotationStatusChange = true; this.clearGeoMap(); this.reloadAnnotations()});
		this.evsubscr2 = this.ea.subscribe('annotations-deleted', () => {this.annotationStatusChange = true; this.clearGeoMap(); this.reloadAnnotations()});

		this.voctagvisible = true;
		this.geotagvisible = true;

		this.annotationsLoaded = false;
	}

	get Loading(){
		return this.loading;
	}

	get space() { return this.spaceServices.active; }
	get nowShowing() {
		return $('#annotationShowDropdown .active').text();
	}

	get suggestionsActive() { return this.suggestedAnnotations.length !== 0; }

	activate(params) {
		if ((params.record || params.parent.selectedRecord) && params.action=="item") {
			this.record.fullres = '/assets/img/loader.gif';
			$('#mediadiv').find('img').attr('src','/assets/img/loader.gif');
			$('#mediadiv').find('img').css('display', '');

			this.parent = params.parent;
			this.groupAnnotationsActive = true;
				this.placeholderText = "Start typing a term"
			if (this.space !== null && this.space.hasModule('itemview'))  {
				this.groupAnnotationsActive = false;
				this.placeholderText = "Start typing an instrument"
			}
			if (params.record) {
				this.record = new Record(params.record.data);
			} else {
			   this.record = this.parent.selectedRecord;
			}

			this.id = this.record.dbId;
			this.showdescription = this.record.description;
			this.descriptionlang = this.record.defaultlanguage;
			if(this.record.dbId) {
				this.getAnnotations();
			}

			this.show();
		}
	}

	close(newpage){
		if (this.parent) {
		  this.parent.selectedRecord = null;
		  this.record = new Record();
		}

		this.annotations.splice(0, this.annotations.length);
		this.vocLevel.clear();
		this.geoLevel.clear();
		this.clearGeoMap();

		var vid = document.getElementById("mediaplayer");
		 if (vid != null) {
			 vid.parentNode.removeChild(vid);
		}
		this.similar.splice(0, this.similar.length);
		this.related.splice(0, this.related.length);
		this.relatedItems={};
		this.selvocabularies.splice(0, this.selvocabularies.length);
		this.suggestedAnnotations.splice(0, this.suggestedAnnotations.length);
		this.selectedAnnotation = null;
		this.prefix = '';
		$("ul#itemTabs > li:not(:first)").removeClass('active');
		$('body').css('overflow','visible');
		$( '.itemview' ).fadeOut();
		if(newpage){
			this.router.navigateToRoute(newpage);
			return;
		}
		if(!this.parent && !newpage){
			this.router.navigateToRoute("home");
			return;
		}

		this.annotationsLoaded = false;
	};

	detached(){
		$('body').css('overflow','visible');
		this.evsubscr1.dispose();
		this.evsubscr2.dispose();
	}


	get isLiked(){
		return this.recordServices.isLiked(this.record.externalId);
	}

	isAnnotationMode() {
		if(( typeof this.space !== 'undefined' ) && (this.space != null)) {
			let mod =  this.space.getModule( "itemview" );
			if(( mod !== undefined ) && typeof mod.annotationMode !== 'undefined' ) {
				return true;
			}
		}
		return false;
	}

	get voctagoptionsvisible() {
 		return this.groupAnnotationsActive && this.voctagvisible;
	}

	get geotagoptionsvisible() {
		return this.geotagvisible;
	}

	setGeoMode(mode) {
		this.geoMode = mode;
	}

	// find a random record, when in crowdsourcing space, from the given collections
	random() {
		let exec = (data)=> {
			this.record = new Record(data[0]);
			this.reset(this.record.dbId, this.record)
		}
		if(( typeof this.space !== 'undefined' ) && (this.space != null)) {
			let mod =  this.space.getModule( "crowdsourcing" );
			if( mod !== undefined ) {
				let collections = mod.collectionsToAnnotate;
				if( collections !== undefined ) {
					this.recordServices.getRandomRecordsFromCollections( collections, 1)
					.then( exec );
				return;
				}
			}
		}
		this.recordServices.getRandomAnnotatedRecords(this.space.dbId, 1, 1)
			.then( exec )
			.catch(error => {
				console.log(error.message);
				toastr.error("Error loading record:"+error.message);
			});
		}

	reset(newid,newrecord) {
		$('#mediadiv').find('img').css('display', 'none');
		this.record=new Record();
		this.record.fullres='/assets/img/loader.gif';
		$('#mediadiv').find('img').css('display', '');

		let newparams=new Object({id:newid,parent:this.parent, record: newrecord, action: 'item'});

		this.related=[];
		this.relatedItems={};
		this.similar=[];
		this.disqusLoaded=false;
		this.annotations.splice(0, this.annotations.length);
		this.vocLevel.clear();
		this.geoLevel.clear();
		this.clearGeoMap();

		this.showdescription = this.record.description;
		this.selvocabularies.splice(0, this.selvocabularies.length);
		this.suggestedAnnotations.splice(0, this.suggestedAnnotations.length);
		this.selectedAnnotation = null;
		this.prefix = '';
		var vid = document.getElementById("mediaplayer");
		 if (vid != null) {
			 vid.parentNode.removeChild(vid);
		}
	    this.activate(newparams);

			this.annotationsLoaded = false;
	  }




	checkURL(url) {
		if(url){
	        return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);}
			return false;
	}

	show(){
		console.log("record image:"+this.record.fullresImage);
		document.body.setAttribute("data-page","media");

		$( '.action' ).removeClass( 'active' );
		$( '.itemview' ).fadeIn();
		if (this.space !=null && this.space.hasModule('itemview')) {
			let itemview = this.space.getModule('itemview');
			if( typeof itemview.annotationMode  !== 'undefined' ) {
				$(`.nav-tabs a[href="#annotations"]`).tab('show');
			}
		} else {
			$('.nav-tabs a[href="#information"]').tab('show');
		}
		$('.mediathumb > img').attr("src","");
		$('body').css('overflow','hidden');
		this.adjustHeight();

		tabAction();
		initTooltip();
		if(this.record.dbId){
			this.addDisqus();
		}
		if (this.record.source_uri && !this.checkURL(this.record.source_uri) && this.record.source_uri.indexOf('archives_items_') > -1) {
			var id = this.record.source_uri.split("_")[2];
			$('#mediadiv').append('<div><iframe id="mediaplayer" src="http://archives.crem-cnrs.fr/archives/items/'+id+'/player/346x130/" height="250px" scrolling="no" width="361px"></iframe></div>');
		} else if (this.record.mediatype=="WEBPAGE") {
			$('#mediadiv').append('<div><iframe id="mediaplayer" src="'+this.record.fullresImage+'" width="100%" height="600px"></iframe></div>');
		} else {

		 if(this.record.mediatype=="VIDEO" && !this.checkURL(this.record.fullresImage) ){
			 $('#mediadiv').append('<video id="mediaplayer" controls width="576" height="324"><source src="' + this.record.fullresImage + '">Your browser does not support HTML5</video>');
		 }else if(this.record.mediatype=="AUDIO"  && !this.checkURL(this.record.fullresImage)){
			 if(this.record.thumbnail)
				 $('#mediadiv').append('<div><img src="'+this.record.thumbnail+'" style="max-width:50%;"/></br></br></div><div><audio id="mediaplayer" controls width="576" height="324"><source src="' + this.record.fullresImage + '">Your browser does not support HTML5</audio></div>');
			 else
		         $('#mediadiv').append('<audio id="mediaplayer" controls width="576" height="324"><source src="' + this.record.fullresImage + '">Your browser does not support HTML5</audio>');
		} else {
			 this.record.forceimage = true;
		}
		}
		if(this.userServices.current){
			if(this.isLiked){
				changeTooltipTitle($(this.fav),'Remove from favorites');

			}
			else{changeTooltipTitle($(this.fav),'Add to favorites');}
		}
		if (this.space != null && this.space.hasModule('itemview')) {
			let itemview = this.space.getModule('itemview');
			if (typeof itemview.annotationMode !== 'undefined') {
				$(`.nav-tabs a[href="#annotations"]`).tab('show');
			}
		} else {
			$("ul#itemTabs > li:not(:first)").removeClass('active');
		}
		$("#annotationInput").focus();

		this.parseAnnotations();
		//}
	}

	attached() {
		$('.action').removeClass('active');
		$('#mediadiv').find('img').css('display', '');
		$("ul#itemTabs > li:not(:first)").removeClass('active');
		$("#grouping-" + this.annotationGrouping).addClass('active');
	}

	toggle(div) {
		$('#' + div).toggle();
		if (div === 'vocdivcontent') {
			this.voctagvisible = !this.voctagvisible;
		} else if (div === 'itemgeomap') {
				this.geotagvisible = !this.geotagvisible
		}
	}

	adjustHeight() {

		// vars
		var wHeight = $( window ).height(),
			wWidth = $( window ).width(),
			itemHeight = wHeight - 70;

		// check
		if( wWidth >= 1200 ) {

			// set height
			$( '.itemopen .itemview' ).css({
				height : itemHeight+"px"
			});
		}
	}

	getRelated(){
		if(this.similar.length==0 && this.related.length==0){
			this.getSimilarRecords(5);
		}
	}

	prefixChanged(newValue, oldValue) {
	//	console.log(this.selectedAnnotation+' '+this.selectedAnnotation.vocabulary+' '+this.selectedAnnotation.label);
			if (newValue === '' || (this.selectedAnnotation != null && this.selectedAnnotation.vocabulary + ": " + this.selectedAnnotation.label == newValue)) {
				this.suggestedAnnotations = [];
				return;
			}
			this.selectedAnnotation = null;
			this.getSuggestedAnnotations(newValue);
	}

	domouseover(index) {
		$('#' + index).addClass('autocomplete-selected');
	}

	domouseout(index) {
		$('#' + index).removeClass('autocomplete-selected');
	}

	selectSuggestedAnnotation(index) {
		this.selectedAnnotation = this.suggestedAnnotations[index];
		this.suggestedAnnotations = [];
		this.errors = this.selectedAnnotation == null;
		if (!this.errors) {
			let self = this;
			this.annotationServices.annotateRecord(this.record.dbId, this.selectedAnnotation)
			.then(() => {
				toastr.success('Annotation added.');
				self.ea.publish('annotations-created', self.record);
				this.prefix = "";
				this.selectedAnnotation = null;
			}).catch((error) => {
				toastr.error('An error has occured');
			});
		}
	}

	getSuggestedAnnotations(prefix) {
		this.lastRequest = prefix;
		this.suggestionsLoading = true;
		this.suggestedAnnotations = this.suggestedAnnotations.slice(0, this.suggestedAnnotations.length);
		this.selectedAnnotation = null;
		let vocabularies = this.selmanualvocabularies;
		if (this.space !== null && this.space.hasModule('itemview')) {
			let itemview = this.space.getModule('itemview');
			if (typeof itemview.annotationMode.vocabularies !== 'undefined') {
				vocabularies = itemview.annotationMode.vocabularies;
			}
		}
		let self = this;
		this.thesaurusServices.getSuggestions(prefix, vocabularies)
		 .then((res) => {
			 if (res.request === self.lastRequest) {
					//this.suggestedAnnotations = res.results.slice(0, 20);
					this.suggestedAnnotations = res.results;

					if (this.suggestedAnnotations.length > 0 && this.suggestedAnnotations[0].exact) {
						this.selectedAnnotation = this.suggestedAnnotations[0];
					}
					this.suggestionsLoading = false;
				}
		 });
	}

	selectManualVocabulary(event) {
		let id = $(event.target).attr('value');

		if (event.target.checked){
			for (let i = 0; i < this.selmanualvocabularies.length; i++) {
				if (this.selmanualvocabularies[i] === id) {
					return true;
				}
			}

			this.selmanualvocabularies.push(id);
			this.getSuggestedAnnotations(this.prefix);
		}

		if (!event.target.checked){
			for (let i = 0; i < this.selmanualvocabularies.length; i++) {
				if (this.selmanualvocabularies[i] === id) {
					this.selmanualvocabularies.splice(i,1);
					this.getSuggestedAnnotations(this.prefix);
					return  true;
				}
			}
		}

		return true;
	}

	enterPressed() {
		if (this.selectedAnnotation !== null) {
			this.suggestedAnnotations = [];
		}
	}

	escapePressed() {
		this.suggestedAnnotations = [];
	}

	getRelatedRecords(){
		      let term="";
		      let self=this;
		       if(this.record.creator.length>0){
		    	   term=this.record.creator;
		    	   this.relatedLabel=' Other media items by '+ this.record.creator;
		       }else if(this.record.dataProvider.length>0){
		    	   term=this.record.dataProvider;
		    	   this.relatedLabel=' Other media items by '+ this.record.dataProvider;
		       }
		       else if(this.record.provider.length>0){
		    	   term=this.record.provider;
		    	   this.relatedLabel=' Other media items by '+ this.record.provider;
		       }
		       else {return;}
		       let query=new Query();
		       this.loading = true;


		       if(this.record.source=="Mint" || this.record.source=="UploadedByUser"){
		    	   query.addSource("WITH Collections");
		       }
		       else{ query.addSource(this.record.source);}

		       query.query( term ).startAtPage( 1 ).withPageSize( 10 );
		       query.simpleFieldFilter( 'resourceType', true, 'CulturalObject' );


		       this.searchServices.search2(query)
				.then(reply => {

					if (reply.status !== 200) {
						if (reply.statusText) {
							throw new Error(reply.statusText);
						} else if (reply.error) {
							throw new Error(reply.error);
						}
					}
					let results = reply.results;

					// one result per source

					let singleResponse = results[0];
					let source=singleResponse.source;
					let sourcedata=singleResponse.items;
					if(sourcedata.length>0){
						for(let k in sourcedata){
							let rec=new Record(sourcedata[k]);

							if(rec.thumbnail && rec.thumbnail.length>0){
								if((rec.dbId && rec.dbId!=self.record.dbId) || !rec.dbId)
								   self.related.push(rec);
							}
							 if(self.related.length>3){break;}
						}
					}
					self.loading=false;
				}).catch(error => {
					self.loading=false;
					console.log(error.message);
					// toastr.error(error.message);
				});

	}
 onErrorOriginal(){
	 console.log("error code created");
	 return '${record.onErrorOriginal()}';
 }

	getSimilarRecords(count){
			let self=this;

			self.loading=true;

	        this.loading = true;

	       this.searchServices.relatedsearch(this.record.dbId,count+5)
			.then(reply => {

				if (reply.status !== 200) {
					if (reply.statusText) {
						throw new Error(reply.statusText);
					} else if (reply.error) {
						throw new Error(reply.error);
					}
				}

				// console.log(JSON.stringify(reply));
				let results = reply;

				// one result per source


				for(let list in results){
					let recs = results[list].records;
					let newrecs = [];
					let count1 = 0;
					for(let r in recs){
					 let rec=new Record(recs[r]);
					 if ((rec.thumbnail && rec.thumbnail.length>0)) {
							newrecs.push(rec);
							count1++;
					 }
					 if (count1>=count)
					     break;
					}
					results[list].records = newrecs;
				}

				self.relatedItems = results;

				// let singleResponse = results[0];
				// let source=singleResponse.source;
				// let sourcedata=singleResponse.records;
				// // similar.title = singleResponse.title;
				// if(sourcedata.length>0){
				// 	for(let k in sourcedata){
				// 		let rec=new Record(sourcedata[k]);
				// 		if(rec.thumbnail && rec.thumbnail.length>0){
				// 			if((rec.dbId && rec.dbId!=self.record.dbId) || !rec.dbId)
				// 				   self.similar.push(rec);
				// 		}
				// 		 if(self.similar.length>3){break;}
				// 	}
				// }
				self.loading=false;
			}).catch(error => {
				self.loading=false;
				console.log(error.message);
				// toastr.error(error.message);
			});
			console.log(JSON.stringify(self.relatedItems));

  }

	likeRecord (recordId, event) {
		event.preventDefault();
    	let $heart=$(event.target);
    	if(this.recordServices.isLiked(this.record.externalId.toString())){
			this.recordServices.unlike(this.record.externalId)
			.then(response => {
				    let index = this.userServices.current.favorites.indexOf(this.record.externalId);
				    if (index > -1) {
				    	this.userServices.current.favorites.splice(index, 1);
						this.userServices.current.count.myFavorites -= 1;
				    }
				    $heart.removeClass('redheart');

				    if($( '[class*="'+this.record.externalId+'"]' ) || $( '[class=*"'+recordId+'"]' )){

				    	 $('[class*="' + recordId + '"]').find("i.heart").parents("li").removeClass('favorited');
						    $('[class*="' + recordId + '"]').find("i.heart").parent("a").each(function(){
						       changeTooltipTitle($( this ),'Add to favorites');
						    });
						    $('[class*="' + this.record.externalId + '"]').find("i.heart").parents("li").removeClass('favorited');
						    $('[class*="' + this.record.externalId + '"]').find("i.heart").parent("a").each(function(){
						       changeTooltipTitle($( this ),'Add to favorites');
						    });

					}
				    changeTooltipTitle($heart,'Add to favorites');
				    toastr.success("Record removed from favorites");
				}).catch(error => {
				toastr.error(error.message);
			});
		}else{
			this.recordServices.like(this.record.data)
					.then(response => {
						let index = this.userServices.current.favorites.indexOf(this.record.externalId);
					    if (index == -1) {
					    	this.userServices.current.favorites.push(this.record.externalId);
							this.userServices.current.count.myFavorites += 1;
					    }
					    $heart.addClass('redheart');
						if($( '[class*="'+this.record.externalId+'"]' ) || $( '[class*="'+recordId+'"]')){
							 $('[class*="' + recordId + '"]').find("i.heart").parents("li").addClass('favorited');
							    $('[class*="' + recordId + '"]').find("i.heart").parent("a").each(function(){
							       changeTooltipTitle($( this ),'Remove from favorites');
							    });
							    $('[class*="' + this.record.externalId + '"]').find("i.heart").parents("li").addClass('favorited');
							    $('[class*="' + this.record.externalId + '"]').find("i.heart").parent("a").each(function(){
							       changeTooltipTitle($( this ),'Remove from favorites');
							    });
						}

						changeTooltipTitle($heart,'Remove from favorites');
						toastr.success("Record added to favorites");

					}).catch(error => {
						toastr.error(error.message);
					});
		}
	};


	doCollect(){
		if(this.parent){
		  this.parent.selectedRecord=this.record;
		  this.parent.actionname="collect";}
		$('.action').removeClass('active');
		$('.action.collect').addClass('active');
	}

	doAnnotate(){
		if (this.parent){
			this.parent.selectedRecord = this.record;
			this.parent.actionname="annotate-rec";
		}
		$('.action').removeClass('active');
		$('.action.annotate-rec').addClass('active');
	}

	addDisqus(){
		let self=this;
		$("#disqus_thread").hide();
		if(!this.disqusLoaded){
	        var disqus_shortname = 'withculture';
	        var disqus_identifier = this.record.dbId;
	        var disqus_url = location.href.replace(location.hash,"")+"#/#!item/"+this.record.dbId;


	        /* * * DON'T EDIT BELOW THIS LINE * * */
	        (function() {
	            var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;
	            dsq.src = '//' + disqus_shortname + '.disqus.com/embed.js';
	            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);
	        })();
	        this.disqusLoaded = true;

		}
		    window.setTimeout( () => {
		    	DISQUS.reset({
		        reload: true,
		        config: function () {
		            this.page.identifier = self.record.dbId;
		            this.page.url =  location.href.replace(location.hash,"")+"#/#!item/"+self.record.dbId;
		            this.page.title = self.record.title;
		            this.language = "en";
		        }

		    });
		    	$("#disqus_thread").show();
		    }, 2000);

	}

	get isLoggedIn() {
		//console.log(this.access);
		return this.userServices.current != null
	}

	createGeoLayer(anns, colors) {
		for (var i = 0; i < this.geoLayers.length; i++) {
		 	this.geoMap.removeLayer(this.geoLayers[i]);
		}
		this.geoLayers = this.geoLayers.splice(0, this.geoLayers.length);


		for (var j = 0; j < anns.length; j++) {
			var iconFeatures = [];

			for (var i = 0; i < anns[j].length; i++) {
				var ann = anns[j][i];
				var xy  = proj.transform([ann.lon, ann.lat], 'EPSG:4326', 'EPSG:3857');
				var iconFeature = new Feature({
					geometry: new geom.Point(xy),
				});

				iconFeatures.push(iconFeature);
			}

			var vectorSource = new source.Vector({
				features: iconFeatures //add an array of features
			});

			var iconStyle = new style.Style({
				image: new style.Icon(/** @type {olx.style.IconOptions} */ ({
					anchor: [0.5, 0.5],
					anchorXUnits: 'fraction',
					anchorYUnits: 'fraction',
					opacity: 0.8,
					color: colors[j],
					src: '/assets/site/img/ui/ic-add.png'
				}))
			});

			var layer = new olLayer.Vector({
				source: vectorSource,
				style: iconStyle
			});

			this.geoLayers.push(layer);
			this.geoMap.addLayer(layer);
		}
	}

	get showGeo() {
		if (this.geoMap == null && this.annotationsLoaded == true && (this.userServices.isAuthenticated() || (!this.userServices.isAuthenticated() && this.geoLevel.groups.length > 0))) {
			var restore = false;
			if ($('#annotations').hasClass('active')) {
					restore = true;
			} else {
				$('#annotations').addClass( 'active' );
			}

			var hide = false;
			if ($('#anntab').hasClass('aurelia-hide')) {
				$('#anntab').removeClass('aurelia-hide');
				hide = true;
			} else {
				hide = false;
			}

			var x = 0;
			var y = 0;
			var zoom = 1;

			var minx, maxx;
			var maxy, miny;

			var anns = [];
			var colors = ['#ff0000'];
			var annGroup = [];
			anns.push(annGroup);
			if (this.geoLevel.groups.length > 0 && this.geoLevel.groups[0].annotations.length > 0) {
				var geoAnns = this.geoLevel.groups[0].annotations;
				for (var i = 0; i < geoAnns.length; i++) {
					var ann = geoAnns[i];
					var xy  = proj.transform([ann.lon, ann.lat], 'EPSG:4326', 'EPSG:3857');
					x += xy[0];
					y += xy[1];

					annGroup.push(ann);

					if (i == 0) {
						minx = xy[0];
						maxx = xy[0];
						miny = xy[1];
						maxy = xy[1];
					} else {
						if (minx > xy[0]) minx = xy[0];
						if (miny > xy[1]) miny = xy[1];
						if (maxx < xy[0]) maxx = xy[0];
						if (maxy < xy[1]) maxy = xy[1];
					}
				}

				x = x/geoAnns.length;
				y = y/geoAnns.length;

				var xd = maxx - minx;
				var yd = maxy - miny;
				var xz = (xd != 0 ? Math.floor(Math.log(xd/1000)/Math.log(2)):0);
				var yz = (yd != 0 ? Math.floor(Math.log(yd/1000)/Math.log(2)):0);
				zoom = Math.min(8,14 - Math.max(xz,yz));
			}

			this.geoMap = new Map({
				controls: control.defaults({
						attributionOptions: /** @type {olx.control.AttributionOptions} */ ({
							collapsible: true
						})
					}),
				layers: [
					new olLayer.Tile({
						source: new source.OSM()
					})
				],
				target: 'itemgeomap',
				view: new View({
					center: [x, y],
					zoom: zoom
				})
			});


			var self = this;
			this.geoMap.on('singleclick', function(evt){
				if (self.geoMode == 1) {
					if (self.userServices.isAuthenticated()) {
						var lonlat = proj.transform(evt.coordinate, 'EPSG:3857','EPSG:4326');

						self.annotationServices.geoAnnotateRecord(self.id, lonlat[0], lonlat[1])
						.then(() => {
							toastr.success('Annotation added.');
							self.ea.publish('annotations-created', self.record.dbId);
						}).catch((error) => {
							toastr.error('An error has occurred');
						});
					}
				} else if (self.geoMode == 2) {
					self.showGeoCoordinates = true;
					var geoAnns = self.geoLevel.groups[0].annotations;
					var show = false;
					self.geoSelected = [];
					for (var i = 0; i < geoAnns.length; i++) {
						geoAnns[i].show = self.action[i];
						show |= self.action[i];
						if (self.action[i]) {
							self.geoSelected.push(geoAnns[i]);
						}
					}

					self.showGeoCoordinates = show;
					self.browseMap(evt);
				}
			});

			this.createGeoLayer(anns, colors);

			if (!restore) {
				$('#annotations').removeClass( 'active' );
			}

			if (hide) {
				$('#anntab').addClass('aurelia-hide');
			}
		}

		return true;
	}

	browseMap(ev) {
		var xy1 = this.geoMap.getEventCoordinate(ev) ;
	 	var zoom = this.geoMap.getView().getZoom();

		var found = false;
		var anns = [];
		var colors = [];
		var annGroup1 = [];
		var annGroup2 = [];
		var annGroup3 = [];
		anns.push(annGroup1);
		anns.push(annGroup2);
		anns.push(annGroup3);
		this.action = [];

		if (this.geoLevel.groups.length > 0 && this.geoLevel.groups[0].annotations.length > 0) {
			var geoAnns = this.geoLevel.groups[0].annotations;
			for (var i = 0; i < geoAnns.length; i++) {
				var ann = geoAnns[i];
				var xy2  = proj.transform([ann.lon, ann.lat], 'EPSG:4326', 'EPSG:3857');

				var x = xy1[0] - xy2[0];
				var y = xy1[1] - xy2[1];

				if (Math.sqrt(x*x+y*y)/1000 < Math.pow(2,11-zoom)) {
					found = true;
					this.action.push(true);
					annGroup1.push(ann);
				} else {
					var black = false;
					for (var j = 0; j < this.geoSelected.length; j++) {
						if (geoAnns[i] === this.geoSelected[j]) {
							this.action.push(false);
							annGroup3.push(ann);
							black = true;
							break;
						}
					}

					if (!black) {
						this.action.push(false);
						annGroup2.push(ann);
					}
				}
			}
		}

		this.createGeoLayer(anns, ['#00ff00', '#ff0000', '#000000']);
	}


	get hasAnnotations() {
		var r = this.regrouping == true || this.annotations.length > 0;
		if (r) {
			this.showGeo();
			} else {
			this.clearGeoMap();
		}

		return r;
	}

	clearGeoMap() {
		$('#itemgeomap').empty();
		this.geoMap = null;
		this.showGeoCoordinates = false;
	}

  get annotationGroupModes() {
		return this.annotationServices.annotationGroupModes();
	}

	get hasMyRejectedAnnotations() {
			if (this.annotationShow != 0) {
				return false;
			}

		 for (let i = 0; i < this.vocLevel.groups.length; i++) {
			 if (this.vocLevel.groups[i].hasRejectedAnnotations()) {
				 return true;
			 }
		 }

		return false;
	}

	groupAnnotations(mode) {
		this.regrouping = true;
		this.annotationGrouping = mode;
		this.showGeoCoordinates = false;
		if (this.annotationStatusChange) {
				this.getAnnotations();
		} else {
			this.parseAnnotations();
		}

		this.regrouping = false;
	}

	showAnnotations(mode) {
		this.regrouping = true;
		this.annotationShow = mode;
		this.showGeoCoordinates = false;
		if (this.annotationStatusChange) {
			this.getAnnotations();
		} else {
			this.parseAnnotations();
		}
		this.regrouping = false;
	}

	getAnnotations() {
		this.vocLevel.clear();
		this.geoLevel.clear();
		this.annotationsLoaded = false;
		this.clearGeoMap();

		this.loading = true;
		let self = this;
		return this.recordServices.getAnnotations(this.id, "Tagging,GeoTagging")
			.then((response) => {
				self.annotations = response;
				self.parseAnnotations();
				self.annotationStatusChange = false;
				self.loading = false;
				self.annotationsLoaded = true;
		}).catch(error => {
			console.log(error.message);
			this.loading = false;
		});
	}

	selectedGrouping(code) {
		return this.annotationGrouping == code;
	}


	isCreator(annotation) {
		for (let j = 0; j < annotation.instances.length; j++) {
			for (let i in annotation.instances[j].admin) {
				if (annotation.instances[j].admin[i].withCreator === this.userServices.current.dbId) {
					return true;
				}
			}
		}
		return false;
	}

	parseAnnotations() {
		this.vocLevel.clear();
		this.geoLevel.clear();
		this.clearGeoMap();

		let anns = this.annotations;
		let count = 0;

		for (let i = 0; i < anns.length; i++) {
			let ann = anns[i];

			if (this.annotationShow == 0) {
				let f = false;
				for (let j = 0, len = ann.annotators.length; j < len; j++) {
					if (ann.annotators[j].withCreator == this.userServices.current.dbId) {
						f = true;
						break;
					}
				}

				if (!f)
					continue;
			} else {
				if (this.annotationShow == 1) {
					if (ann.score == null || ann.score.approvedBy == null || ann.score.approvedBy.length == 0) {
					 	continue;
					}
				} else if (this.annotationShow == 3) {
					if (ann.score == null || ann.score.rejectedBy == null || ann.score.rejectedBy.length == 0) {
					 	continue;
					}
				} else if (this.annotationShow == 2) {
				}
			}

			let ak = this.vocLevel;

			let approved = false;
			let rejected = false;
			if (this.userServices.current != null && ann.score != null) {
				if (ann.score.approvedBy != null) {
					let approvedBy = ann.score.approvedBy;
					for (let j = 0; j < approvedBy.length; j++) {
						 if (approvedBy[j] == this.userServices.current.dbId) {
						 	approved = true;
						 	break;
						 }
					}
				}
				if (ann.score.rejectedBy != null) {
					let rejectedBy = ann.score.rejectedBy;
					for (let j = 0; j < rejectedBy.length; j++) {
						if (rejectedBy[j] == this.userServices.current.dbId) {
							rejected = true;
							break;
						}
					}
				}
			}

			let annid = ann.dbId;
			if (ann.motivation === 'Tagging') {
				let ag = [];
				if (this.annotationGrouping == 0) {
					let tag;
					if (ak.groups.length == 0) {
						tag = new AnnotationGroup({ label: "All"}, this);
						ak.groups.push(tag);
					} else {
						tag = ak.groups[0];
					}
					ag.push(tag);
				} else if (this.annotationGrouping == 1) {
					let tag;
					if (ann.body.uriVocabulary == undefined) {
						ann.body.uriVocabulary = "free";
					}
					let voc = ann.body.uriVocabulary.toLowerCase();
					if (ak.annotationGroupingMap[voc] == undefined) {
						if (voc === "wikidata") {
							tag = new AnnotationGroup({ label: "Wikidata"}, this);
						} else if (voc === "free") {
							tag = new AnnotationGroup({ label: "Free text"}, this);
						} else {
							tag = new AnnotationGroup({ label: this.annotationServices.getVocabularyLabel(voc.toLowerCase())}, this);
						}
							ak.annotationGroupingMap[voc] = ak.groups.length;
							ak.groups.push(tag);
					} else {
						tag = ak.groups[ak.annotationGroupingMap[voc]];
					}
					ag.push(tag);
				} else if (this.annotationGrouping === 2) {
					for (let j = 0; j < ann.annotators.length; j++) {
						let tag;
						let voc = ann.annotators[j].generator;
						if (ak.annotationGroupingMap[voc] == undefined) {
							tag = new AnnotationGroup({ label: voc }, this);
							ak.annotationGroupingMap[voc] = ak.groups.length;
							ak.groups.push(tag);
						} else {
							tag = ak.groups[ak.annotationGroupingMap[voc]];
						}
						ag.push(tag);
					}
				}

				let aj = [];
				for (let k = 0; k < ag.length; k++) {
					aj.push(ag[k].check(ann));
				}

				for (let k = 0; k < ag.length; k++) {
					let j = aj[k];
					if (aj[k] == -1) {
						j = ag[k].annotations.length;
						ag[k].annotations.push(new AnnotationKey(ann, this));
					}

					ag[k].annotations[j].add(new AnnotationInstance(ann, approved, rejected, this, ag[k].annotations[j]));
				}
			} else if (ann.motivation === 'GeoTagging') {

				var geoGroup;
				if (this.geoLevel.groups.length == 0) {
					geoGroup = new AnnotationGroup({ label: "Coordinates" }, this);
					this.geoLevel.groups.push(geoGroup);
				} else {
					geoGroup = this.geoLevel.groups[0];
				}

				let j = geoGroup.check(ann)
				if (j == -1) {
					j = geoGroup.annotations.length;
					geoGroup.annotations.push(new AnnotationKey(ann, this));
				}

				geoGroup.annotations[j].add(new AnnotationInstance(ann, approved, rejected, this, geoGroup.annotations[j]));
			}
		}

 		for (let k = 0; k < this.vocLevel.groups.length; k++) {
			this.vocLevel.groups[k].annotations.sort((a,b) => this.sortAnnotations(a,b));
 		}

		this.vocLevel.groups.sort((a,b) => { return a.label.localeCompare(b.label)});
	}

	get hasGeoAnnotations() {
		return this.geoLevel.group.length > 0;
	}

	sortAnnotations(a, b) {
		if (a.vocabulary < b.vocabulary) {
			return -1;
		} else if (a.vocabulary > b.vocabulary) {
			return 1;
		} else {
			if (a.label < b.label) {
				return -1;
			} else if (a.label < b.label) {
				return 1;
			} else {
				return 0;
			}
		}
	}

	reloadAnnotations() {
		if (this.annotationStatusChange) {
			this.getAnnotations();
			this.annotationStatusChange = false;
		}
	}

	deleteRejectedAnnotations() {
		let self = this;
		this.annotationServices.deleteRejectedAnnotations(this.id)
		.then((response) => {
				toastr.success("The annotations have been successfully removed.");
				// self.getAnnotations();
				// self.annotationStatusChange = false;
				self.ea.publish('annotations-deleted', self.id);
		}).catch((error) => {
			toastr.error("An error has occurred.");
			console.log(error.message);
		});
	}

	changeLanguage(lang) {
		if(lang.lang=="default"){
			this.showdescription = this.record.description;
		} else {
		   this.showdescription = this.record.alldescription[lang.lang];
		}
		this.descriptionlang = lang.lang;
	}

	changeFieldLanguage(dcfield,idx){
		try{
			if(dcfield.label=="created" && dcfield.value[idx].free){
				$('#'+dcfield.label+'id').html(dcfield.value[idx].free);
			}
			else
			$('#'+dcfield.label+'id').html(dcfield.value[idx].join(", "));
			$('#'+dcfield.label+'id').siblings('.language-options').find('li > a').removeClass('active');
			$("."+dcfield.label +".description-language."+dcfield.langs[idx].lang).addClass('active');

		}catch(err) {
			console.log(err);
		}

	  }
}

class AnnotationLevel {
	constructor() {
		this.groups = [];
		this.annotationGroupingMap = {};
	}

	clear() {
		this.groups.splice(0, this.groups.length);
		this.annotationGroupingMap = {};
	}
}

class AnnotationGroup {
	constructor(data, item) {
		this.label = data.label;
		this.annotations = [];
		this.item = item;

		this.isExpanded = true;
	}

	check(ann) {
		for (let j = 0; j < this.annotations.length; j++) {
			if (this.annotations[j].motivation == ann.motivation) {
				if (ann.motivation == 'Tagging' && this.annotations[j].uri == ann.body.uri ||
					  ann.motivation == 'GeoTagging' && this.annotations[j].lon == ann.body.wgslon && this.annotations[j].lat == ann.body.wgslat) {
					this.annotations[j].dbids.push(ann.dbId);
					return j;
				}
			}
		}

		return -1;
	}

	deleteAll() {
		let funcs = [];

		let self = this;
		for (let i = 0; i < this.annotations.length; i++) {
			if (this.annotations[i].show) {
				for (let j = 0; j < this.annotations[i].instances.length; j++) {
					funcs.push(new Promise(function (resolve, reject) {self.item.annotationServices.delete(self.annotations[i].instances[j].id).then(() => resolve())}));
				}
			}
		}

		Promise.all(funcs).then(() => {
				self.item.ea.publish('annotations-deleted');
			}).catch(error => {
				self.item.ea.publish('annotations-deleted');
				console.log(error.message);
			});
	}

	approveAll() {
		for (let i = 0; i < this.annotations.length; i++) {
			this.annotations[i].approveAnnotation(true);
		}
	}

	rejectAll() {
		for (let i = 0; i < this.annotations.length; i++) {
			this.annotations[i].rejectAnnotation(true);
		}
	}

	hasRejectedAnnotations() {
		for (let i = 0; i < this.annotations.length; i++) {
			for (let j = 0; j < this.annotations[i].instances.length; j++) {
				if (this.annotations[i].instances[j].rejected) {
					return true;
				}
			}
		}
	}

	get expanded() {
		return this.isExpanded;
	}

	toggleVisibility() {
		this.isExpanded = !this.isExpanded;
	}

}

	class AnnotationKey {

		constructor(ann, item) {
			if (ann.motivation == "Tagging") {
				this.uri = ann.body.uri;
				this.vocabulary = ann.body.uriVocabulary.toLowerCase();
				this.label = null;
				if (ann.body.label != null && ann.body.label.default != null && ann.body.label.default.length > 0) {
					this.label = ann.body.label.default[0];
				}
				this.show = true;
			} else if (ann.motivation = "GeoTagging") {
				this.lon = ann.body.coordinates.longitude;
				this.lat = ann.body.coordinates.latitude;
				this.show = false;
			}

			this.motivation = ann.motivation;

			this.dbids = [];
			this.instances = [];
			this.item = item;

			this.isExpanded = false;

			this.total_approved = 0;
			this.total_rejected = 0;
			this.confidence = 0;
		}

		add(ai) {
			this.instances.push(ai);
			this.total_approved += ai.total_approved;
			this.total_rejected += ai.total_rejected;

			this.confidence = 0;
			let count = 0 ;
			for (let i = 0; i < this.instances.length; i++) {
				let inst = this.instances[i];
				for (let j = 0; j < inst.admin.length; j++) {
					if (inst.admin[j].confidence > 0) {
							this.confidence += inst.admin[j].confidence;
					}
				}
			}

			if (count > 0) {
				this.confidence /= count;
			}

			if (this.confidence > 0) {
				if (this.confidence < 0.30) {
					this.conf = "<span style='color:#ff3300'>very low</span>";
				} else if (this.confidence < 0.50) {
					this.conf = "<span style='color:#ff0000'>low</span>";
				} else if (this.confidence < 0.70) {
					this.conf = "<span style='color:#cc9900'>medium</span>";
				} else if (this.confidence < 0.90) {
					this.conf = "<span style='color:#009900'>high</span>";
				} else if (this.confidence <= 1.) {
					this.conf = "<span style='color:#009933'>very high</span>";
				}
			} else {
				this.conf = "";
			}
		}

		get hasInstances() {
			for (let j = 0; j < this.instances.length; j++) {
				if (this.instances[j].selector) {
					return true;
				}
			}

			return false;
		}

		get approved() {
			let count = 0;
			for (let j = 0; j < this.instances.length; j++) {
				if (this.instances[j].approved) {
					count++;
				}
			}

			if (count == this.instances.length) {
				return true;
			} else {
				return false;
			}
		}

		get papproved() {
			let count = 0;
			for (let j = 0; j < this.instances.length; j++) {
				if (this.instances[j].approved) {
					count++;
				}
			}

			if (count == 0 || count == this.instances.length) {
				return false;
			} else {
				return true;
			}
		}

		get prejected() {
			let count = 0;
			for (let j = 0; j < this.instances.length; j++) {
				if (this.instances[j].rejected) {
					count++;
				}
			}

			if (count == 0 || count == this.instances.length) {
				return false;
			} else {
				return true;
			}
		}


		get rejected() {
			let count = 0;
			for (let j = 0; j < this.instances.length; j++) {
				if (this.instances[j].rejected) {
					count++;
				}
			}

			if (count == this.instances.length) {
				return true;
			} else {
				return false;
			}
		}

		get info() {
			let html = "<div style='text-align: left;'><ul>";
			for (let j = 0; j < this.instances.length; j++) {
				//html += "Approvals: " + this.instances[j].total_approved + " Rejections: " + this.instances[j].total_rejected;
				for (let i in this.instances[j].admin) {
					let cadmin = this.instances[j].admin[i];
					if (cadmin.username == "IMEC") {
						html = "";
						cadmin.created = "2019/11/01"
					}
					html += "<li>";
			  	html += "Created by <b>" + cadmin.username + "</b> at " + cadmin.created + " ";
					html += "using <b>" + cadmin.generator+ "</b>";
					html += "</li>";
				}
			}

			html += "</ul></div>";

			return html;
		}

		deleteAnnotation() {
			let funcs = [];

			let self = this;
			for (let j = 0; j < this.instances.length; j++) {
				funcs.push(new Promise(function (resolve, reject) { self.item.annotationServices.delete(self.instances[j].id).then(() => resolve())}));
			}

			Promise.all(funcs).then(() => {
					self.item.ea.publish('annotations-deleted');
				}).catch(error => {
					self.item.ea.publish('annotations-deleted');
					console.log(error.message);
				});
		}

		recountAnnotations() {
			this.total_approved = 0;
			this.total_rejected = 0;

			for (let j = 0; j < this.instances.length; j++) {
				this.total_approved += this.instances[j].total_approved;
				this.total_rejected += this.instances[j].total_rejected;
			}
		}

		approveAnnotation(force) {
			if (force) {
				for (let j = 0; j < this.instances.length; j++) {
					this.instances[j].approveAnnotation(true);
				}
			} else {
				let approved = 0;
				for (let j = 0; j < this.instances.length; j++) {
					if (this.instances[j].approved) {
						approved++;
					}
				}

				if (approved != this.instances.length) {
					for (let j = 0; j < this.instances.length; j++) {
						this.instances[j].approveAnnotation(true);
					}
				} else {
					for (let j = 0; j < this.instances.length; j++) {
						this.instances[j].unscoreAnnotation();
					}
				}
			}

			this.recountAnnotations();
		}

		rejectAnnotation(force) {
			if (force) {
				for (let j = 0; j < this.instances.length; j++) {
					this.instances[j].rejectAnnotation(true);
				}
			} else {
				let rejected = 0;
				for (let j = 0; j < this.instances.length; j++) {
					if (this.instances[j].rejected) {
						rejected++;
					}
				}

				if (rejected != this.instances.length) {
					for (let j = 0; j < this.instances.length; j++) {
						this.instances[j].rejectAnnotation(true);
					}
				} else {
					for (let j = 0; j < this.instances.length; j++) {
						this.instances[j].unscoreAnnotation();
					}
				}
			}
			this.recountAnnotations();
		}

		get expanded() {
			return this.isExpanded;
		}

		toggleVisibility() {
			this.isExpanded = !this.isExpanded;
		}

		getAnnotationLabel() {
			if (this.motivation == "Tagging") {
				if (this.label == null) {
					return (this.vocabulary != null ? this.vocabulary : "unknown") + " : " + this.uri;
				} else {
					return (this.vocabulary != null ? this.vocabulary : "unknown") + " : " + this.label;
				}
			} else if (this.motivation == "GeoTagging") {
				return MapUtils.coordinates2degrees(this.lon, this.lat);
			}
		}
	}

	class AnnotationInstance {

		constructor(ann, approved, rejected, item, key) {
			this.id = ann.dbId;

			this.textSelector = false;
			this.imageSelector = false;

			if (ann.target.selector != null) {
				if (ann.target.selector.property) {
					this.textSelector = true;
					this.property = ann.target.selector.property;
					this.text = ann.target.selector.origValue;
					this.start = ann.target.selector.start;
					this.end  = ann.target.selector.end;
				} else if (ann.target.selector.imageWithURL) {
					this.imageSelector = true;
					this.imageURL = ann.target.selector.imageWithURL;
					if (this.imageURL.startsWith("/media/byUrl?url=")) {
						this.imageURL = settings.baseUrl + ann.target.selector.imageWithURL;
					}
					this.svg = ann.target.selector.text;
				}
			}

			this.selector = this.textSelector || this.imageSelector;

			this.approved = approved;
			this.rejected = rejected;
			this.total_approved = ann.score != null && ann.score.approvedBy != null ? ann.score.approvedBy.length : 0;
			this.total_rejected = ann.score != null && ann.score.rejectedBy != null ? ann.score.rejectedBy.length : 0;

			this.item = item;
			this.key = key;
			this.admin = [];

			for (let i in ann.annotators) {
				this.admin.push(ann.annotators[i]);
			}

			this.confidence = 0;
			let count = 0 ;
			for (let j = 0; j < this.admin.length; j++) {
				if (this.admin[j].confidence > 0) {
						this.confidence += this.admin[j].confidence;
				}
			}

			if (count > 0) {
				this.confidence /= count;
			}

			if (this.confidence > 0) {
				if (this.confidence < 0.30) {
					this.conf = "<span style='color:#ff3300'>very low</span>";
				} else if (this.confidence < 0.50) {
					this.conf = "<span style='color:#ff0000'>low</span>";
				} else if (this.confidence < 0.70) {
					this.conf = "<span style='color:#cc9900'>medium</span>";
				} else if (this.confidence < 0.90) {
					this.conf = "<span style='color:#009900'>high</span>";
				} else if (this.confidence <= 1.) {
					this.conf = "<span style='color:#009933'>very high</span>";
				}
			} else {
				this.conf = "";
			}
		}

		get hasSelector() {
			// return (this.property != null && this.text != null) || (this.imageURL != null && this.svg != null);
			return (this.property != null && this.text != null) || this.imageURL != null;
		}

		deleteAnnotation() {
			let self = this;
			this.item.annotationServices.delete(this.id)
			.then(() => {
				self.item.ea.publish('annotations-deleted');
			}).catch(error => {
				console.log(error.message);
			});
		}

		onApprove() {
			if (!this.approved) {
				this.approved = true;
				this.total_approved++;
			}

			if (this.rejected) {
				this.rejected = false;
				this.total_rejected--;
			}
			this.item.annotationStatusChange = true;
			this.key.recountAnnotations();
		}

		approveAnnotation(force) {
			if (force || !this.approved) {
				let self = this;
				this.item.annotationServices.approve(this.id)
				.then(() => {
					self.onApprove();
				}).catch(error => {
					console.log(error.message);
				});
			} else {
				this.unscoreAnnotation();
			}
		}

		onReject() {
			if (!this.rejected) {
				this.rejected = true;
				this.total_rejected++;
			}

			if (this.approved) {
				this.approved = false;
				this.total_approved--;
			}
			this.item.annotationStatusChange = true;
			this.key.recountAnnotations();
		}

		rejectAnnotation(force) {
			if (force || !this.rejected) {
				let self = this;
				this.item.annotationServices.reject(this.id)
				.then(() => {
					self.onReject();
				}).catch(error => {
					console.log(error.message);
				});
			} else {
				this.unscoreAnnotation();
			}
		}

		onUnscore() {
			if (this.approved) {
				this.total_approved--;
				this.approved = false;
			}
			if (this.rejected) {
				this.total_rejected--;
				this.rejected = false;
			}
			this.item.annotationStatusChange = true;
			this.key.recountAnnotations();
		}

		unscoreAnnotation() {
			if (this.approved || this.rejected) {
				let self = this;
				this.item.annotationServices.unscore(this.id)
				.then(() => {
					self.onUnscore();
				}).catch(error => {
					console.log(error.message);
				});
			}

		}


		showExtract() {
			if (this.textSelector) {
				let s = Math.max(0, this.start - 50);
				let e = Math.min(this.text.length, this.end + 50);

				if (s > 0)
					while (this.text.substr(s, 1) != " " && s < this.start)
						s++;

				if (e < this.text.length)
					while (this.text.substr(e - 1, 1) != " " && e > this.end)
						e--;

				return (s > 0?"... ":"") + this.text.substring(s, this.start) + "<span class='tag-selection'>" + this.text.substring(this.start,this.end) + "</span>" + this.text.substring(this.end,e) + (e < this.text.length?" ...":"");
			} else if (this.imageSelector) {
						if (this.imageURL != null) {
							let selector = "";
							if (this.svg != null) {
								let x = /<polygon points=['\"](.*?)['\"].*?>/.exec(this.svg);
								if (x.length > 1) {
									selector = `<polygon points='${x[1]}' fill-opacity='0.1' style='fill:red;stroke:red;stroke-width:1'/>`;
								}
							}

							let img = new Image();
							let self = this;
							img.onload = function() {
								$(`#annimage${self.id}`).html("<svg style='height:100%;width:100%;max-height:" + img.height + "px; max-width:" + img.width + "px;' viewBox='0 0 " + img.width + " " + img.height + "' preserveAspectRatio='xMidYMin meet' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>" +
																		"<image width='" + img.width + "' height='" + img.height + "' xlink:href='" + self.imageURL + "'/>" +
																		selector +
																		"</svg>");
							};
							img.src = this.imageURL;
						}

					return `<div id="annimage${this.id}"></div>`;
				}
		}

		get info() {
			let html = "<div style='text-align: left;'><ul>";
			//html += "Approvals: " + this.total_approved + " Rejections: " + this.total_rejected;
			for (let i in this.admin) {
				let cadmin = this.admin[i];
				html += "<li>";
			  html += "Created by <b>" + cadmin.username + "</b> at " + cadmin.created + " ";
				html += "using <b>" + cadmin.generator+ "</b>";
				html += "</li>";
			}

			html += "</ul></div>";

			return html;
		}

	}
