import { bindable, inject } from 'aurelia-framework';
import { UserServices } from 'UserServices.js';
import { SpaceServices } from 'SpaceServices.js';
import { initHeader } from 'utils/ui';
import { initSearchHeader, initMobileMenu } from 'utils/Plugin.js';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Notification } from 'Notification.js';
import settings from 'global.config.js';
import {singleton} from 'aurelia-framework';


@inject(UserServices, Router, EventAggregator, SpaceServices, DialogService)
export class NavBar {

	@bindable router = null;

	constructor(userServices, router, eventAggregator, spaceServices, dialogService) {
		this.userServices = userServices;
		this.router = router;
		this.locked = false;
		this.ea = eventAggregator;
		this.spaceServices = spaceServices;
		this.dialogService = dialogService;
		this.antikleia = false;
	}

	// Properties
	get isAuthenticated() { return this.userServices.isAuthenticated(); }
	get user() { return this.userServices.current; }
	get space() { return this.spaceServices.active; }
	get spacelogo() {
		return this.spaceServices.active ? this.spaceServices.active.logo : this.spaceServices.with.logo;
	}
	get privateSpace() { return this.spaceServices.active &&  this.spaceServices.active.privateGroup; }
	get plainView() { return this.spaceServices.active &&  this.spaceServices.active.username == "evinos"; }

	// UI Functions
	showProfile() {
		$('.action').removeClass('active');
		$('.action.profile').addClass('active');
	}

	loginPopup(collection) {
		this.dialogService.open({
			viewModel:  PLATFORM.moduleName('widgets/logindialog/logindialog')
		}).then((response) => {
			if (!response.wasCancelled) {
				console.log('NYI - Login User');
			} else {
				console.log('Login cancelled');
			}
		});
	}

	logout(redirectUri) {
		this.userServices.logout(redirectUri);
	}

	activate(params) {
		if ('scrollRestoration' in history) {
			history.scrollRestoration = 'manual';
		}
		if (typeof(params.page) !== 'undefined') {
			this.type = params.page;
		} else {
			this.type = 'default';
		}
		window.clearInterval(window.headerInterval);
		if (params.locked) {
			this.locked = params.locked;
		}
		if (!this.locked) {
			initHeader();
		}

		// Socket initialization
		let domainUrl = `${settings.baseUrl}`.replace('http://', '');
		domainUrl = `${settings.baseUrl}`.replace('https://', '');
		this.notificationSocket = new WebSocket('wss://' + domainUrl + '/notifications/socket');
		if (this.userServices.current !== null) {
			this.waitForConnection(() => {
				this.notificationSocket.send('{"action":"login","id":"' + this.userServices.current.id + '"}');
			}, 1000);
		}

		this.notificationSocket.onopen = () => {
			// Socket open
		};

		this.notificationSocket.onmessage = (evt) => {
			let notification = new Notification(JSON.parse(evt.data));
			let message = this.extractNotificationMessage(notification);
			if (message !== null) {
				this.ea.publish('notification-received', notification);
				toastr.info(message);
			}

			switch (notification.activity) {
			case 'COLLECTION_SHARE':
				this.ea.publish('collection-shared', notification.resource);
				break;
			case 'EXHIBITION_SHARE':
				this.ea.publish('exhibition-shared', notification.resource);
				break;
			case 'COLLECTION_UNSHARED':
				this.ea.publish('collection-unshared', notification.resource);
				break;
			case 'EXHIBITION_UNSHARED':
				this.ea.publish('exhibition-unshared', notification.resource);
				break;
			case 'GROUP_REQUEST_ACCEPT':
				this.ea.publish('group-joined', notification.group);
				break;
			case 'GROUP_REMOVAL':
				this.ea.publish('group-left', notification.group);
				break;
			case 'RECORD_ANNOTATING_COMPLETED':
				this.ea.publish('annotations-created', notification.resource);
				break;
			default: // Do nothing
			}
		};

		this.notificationSocket.onclose = (evt) => {
			console.log('disconnected');
		};

		this.ea.subscribe('login', () => this.socketLoginHandler());
		this.ea.subscribe('logout', () => this.socketLogoutHandler());
		if (this.space && this.space.username == "antikleia") {
			this.antikleia = true;
		}
	}

	attached() {
		initMobileMenu();
		initSearchHeader();
	}

	doGlobalSearch(event) {
		if (event.which === 13) {
			//do search
			this.router.navigateToRoute('search', {term: this.searchterm.value});
		}
		event.preventDefault();
	}

	waitForConnection(callback, interval) {
		if (this.notificationSocket.readyState === 1) {
			callback();
		} else {
			// optional: implement backoff for interval here
			setTimeout(() => {
				this.waitForConnection(callback, interval);
			}, interval);
		}
	}

	socketLoginHandler() {
		this.waitForConnection(() => {
			this.notificationSocket.send('{"action":"login","id":"' + this.userServices.current.id + '"}');
		}, 1000);
	}

	socketLogoutHandler() {
		this.waitForConnection(() => {
			this.notificationSocket.send('{"action":"logout","id":"' + this.userServices.current.id + '"}');
		}, 1000);
	}

	extractNotificationMessage(value) {
		switch (value.activity) {
		case 'GROUP_INVITE':
			return `<strong>${value.senderName}</strong> invites you to join <strong>${value.groupName}</strong>`;
		case 'GROUP_INVITE_ACCEPT':
			return `<strong>${value.senderName}</strong> joined <strong>${value.groupName}</strong>`;
		case 'GROUP_INVITE_DECLINED':
			return `<strong>${value.senderName}</strong> declined your invitation to join <strong>${value.groupName}</strong>`;
		case 'GROUP_REQUEST':
			return `<strong>${value.senderName}</strong> wants to join <strong>${value.groupName}</strong>`;
		case 'GROUP_REQUEST_ACCEPT':
			return `You joined <strong>${value.groupName}</strong>`;
		case 'GROUP_REQUEST_DENIED':
			return `Your request to join <strong>${value.groupName}</a> was declined`;
		case 'COLLECTION_SHARE':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.senderName}</strong> wants to share collection <strong>${value.resourceName}</strong> with <strong>${value.shareInfo.userOrGroupName}</strong>`;
			}
			return `<strong>${value.senderName}</strong> wants to share collection <strong>${value.resourceName}</strong> with you`;
		case 'COLLECTION_SHARED':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.resourceName}</strong> is now shared with <strong>${value.shareInfo.userOrGroupName}</strong>`;
			}
			return `<strong>${value.resourceName}</strong> is now shared with <strong>${value.senderName}</strong>`;
		case 'COLLECTION_UNSHARED':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.resourceName}</strong> is no longer shared with <strong>${value.shareInfo}.userOrGroupName</strong>`;
			}
			return `<strong>${value.resourceName}</strong> is no longer shared with you`;
		case 'COLLECTION_REJECTED':
			return `<strong>${value.shareInfo.userOrGroupName}</strong> is not interested in collection <strong>${value.resourceName}</strong>`;
		case 'EXHIBITION_SHARE':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.senderName}</strong> wants to share exhibition <strong>${value.resourceName}</strong> with <strong>${value.shareInfo.userOrGroupName}</strong>`;
			}
			return `<strong>${value.senderName}</strong> wants to share exhibition <strong>${value.resourceName}</strong> with you`;
		case 'EXHIBITION_SHARED':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.resourceName}</strong> is now shared with <strong>${value.shareInfo.userOrGroupName}</strong>`;
			}
			return `<strong>${value.resourceName}</strong> is now shared with <strong>${value.senderName}</strong>`;
		case 'EXHIBITION_UNSHARED':
			if (value.shareInfo.sharedWithGroup) {
				return `<strong>${value.resourceName}</strong> is no longer shared with <strong>${value.shareInfo}.userOrGroupName</strong>`;
			}
			return `<strong>${value.resourceName}</strong> is no longer shared with you`;
		case 'EXHIBITION_REJECTED':
			return `<strong>${value.shareInfo.userOrGroupName}</strong> is not interested in exhibition <strong>${value.resourceName}</strong>`;
		case 'COLLECTION_ANNOTATING_COMPLETED':
			return `Annotating of collection <strong>${value.resourceName}</strong> has finished`;
		case 'RECORD_ANNOTATING_COMPLETED':
			return `Annotating of record <strong>${value.resourceName}</strong> has finished`;
		case 'MESSAGE':
			return value.message;
		default:
			return null;
		}
	}

}
