import { inject } from 'aurelia-framework';
import { HttpClient, json } from 'aurelia-fetch-client';
import fetchConfig from 'fetch.config.js';
import settings from 'global.config.js';
import { checkStatus } from 'fetch.config.js';
import { Space } from 'Space.js';


@inject(HttpClient)
export class SpaceServices {

	active = {};
	with = {};

	constructor(http) {
		http.configure(fetchConfig);
		this.http = http;
	}

	loadWITH() {
		return this.getSpace(settings.space).then(response => {
			this.with = new Space(response);
		});
	}

	getSpace(name) {
		// If already loaded, don't load it again.
		if (this.active && this.active.name === name) {
			let self=this;
			return new Promise(function(resolve, reject) {
				resolve(self.active);
			});
		}
		return this.http.fetch(`/group/space?name=${name}`, {
			method: 'GET'
		}).then(checkStatus).then((response) => response.json());
	}

	saveSpaceKey(id, keyname, jsonkey) {
		// console.log(JSON.stringify(jsonkey));
		return this.http.fetch(`/group/${id}/uiSettings?key=${keyname}`, {
			method: 'PUT',
			body: json(jsonkey)
		}).then(checkStatus);
	}

	deleteSpaceKey(id, keyname) {
		return this.http.fetch(`/group/${id}/uiSettings?key=${keyname}`, {
			method: 'DELETE',
			body: json('{}')	// Bug in the backend requires a json body even for delete
		}).then(checkStatus);
	}

	getCompletedAnnotations(id) {
		return this.http.fetch(`/record/annotationCount?groupId=${id}`, {
			method: 'GET'
		}).then(checkStatus).then((response) => response.json());
	}
}
