import {noView} from 'aurelia-framework';
import { stringComparator } from 'utils/comparators.js';

@noView
export class SortCollectionsValueConverter {
	toView(array, propertyName, direction) {
		let factor = direction === 'ascending' ? 1 : -1;
		return array.sort((a, b) => {
			return stringComparator(a[propertyName], b[propertyName]) * factor;
		});
	}
}
